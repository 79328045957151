"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// index.ts
var i11n_exports = {};
__export(i11n_exports, {
  translate: () => translate,
  useTranslations: () => useTranslations
});
module.exports = __toCommonJS(i11n_exports);

// src/translate.ts
var import_lodash = __toESM(require("lodash.get"));

// src/locales/es/es.json
var es_exports = {};
__export(es_exports, {
  "/claims": () => _claims,
  "/companies": () => _companies,
  "/complaints": () => _complaints,
  "/integrations": () => _integrations,
  "/recordings": () => _recordings,
  "/users": () => _users,
  actions: () => actions,
  backends: () => backends,
  bid: () => bid,
  breadcrumb: () => breadcrumb,
  chart: () => chart,
  circulation: () => circulation,
  claim: () => claim,
  "claim.address.label": () => claim_address_label,
  "claim.city.label": () => claim_city_label,
  "claim.country.label": () => claim_country_label,
  "claim.cuit.label": () => claim_cuit_label,
  "claim.first_name.label": () => claim_first_name_label,
  "claim.identification.label": () => claim_identification_label,
  "claim.identification_type.label": () => claim_identification_type_label,
  "claim.last_name.label": () => claim_last_name_label,
  "claim.properties.damage.driver.first_name": () => claim_properties_damage_driver_first_name,
  "claim.properties.damage.driver.last_name": () => claim_properties_damage_driver_last_name,
  "claim.properties.damage.hasInsurance": () => claim_properties_damage_hasInsurance,
  "claim.properties.damage.type": () => claim_properties_damage_type,
  "claim.properties.driver.owner": () => claim_properties_driver_owner,
  "claim.properties.driver.ownership": () => claim_properties_driver_ownership,
  "claim.properties.driver.ownership.owner": () => claim_properties_driver_ownership_owner,
  "claim.properties.insurance.coverage": () => claim_properties_insurance_coverage,
  "claim.properties.insurance.coverage.third_full": () => claim_properties_insurance_coverage_third_full,
  "claim.properties.insurance.insurer": () => claim_properties_insurance_insurer,
  "claim.properties.insurance.policy_number": () => claim_properties_insurance_policy_number,
  "claim.properties.vehicle.category": () => claim_properties_vehicle_category,
  "claim.properties.vehicle.category.with_license_plate": () => claim_properties_vehicle_category_with_license_plate,
  "claim.properties.vehicle.chassis": () => claim_properties_vehicle_chassis,
  "claim.properties.vehicle.document_type": () => claim_properties_vehicle_document_type,
  "claim.properties.vehicle.document_type.title": () => claim_properties_vehicle_document_type_title,
  "claim.properties.vehicle.engine": () => claim_properties_vehicle_engine,
  "claim.properties.vehicle.license_plate": () => claim_properties_vehicle_license_plate,
  "claim.properties.vehicle.make": () => claim_properties_vehicle_make,
  "claim.properties.vehicle.model": () => claim_properties_vehicle_model,
  "claim.properties.vehicle.type": () => claim_properties_vehicle_type,
  "claim.properties.vehicle.type.car": () => claim_properties_vehicle_type_car,
  "claim.properties.vehicle.use": () => claim_properties_vehicle_use,
  "claim.properties.vehicle.version": () => claim_properties_vehicle_version,
  "claim.properties.vehicle.year": () => claim_properties_vehicle_year,
  "claim.social_reason.label": () => claim_social_reason_label,
  "claim.state.label": () => claim_state_label,
  "claim.type.label": () => claim_type_label,
  "claim.victim_relationship.label": () => claim_victim_relationship_label,
  "claim.victim_relationship.own": () => claim_victim_relationship_own,
  "claim.zip_code.label": () => claim_zip_code_label,
  claimants: () => claimants,
  claims: () => claims,
  company: () => company,
  "company-enums.agencies": () => company_enums_agencies,
  "company-enums.claim_public_status": () => company_enums_claim_public_status,
  "company-enums.claim_reference": () => company_enums_claim_reference,
  "company-enums.claim_stage": () => company_enums_claim_stage,
  "company-enums.claim_status": () => company_enums_claim_status,
  "company-enums.complaint_public_status": () => company_enums_complaint_public_status,
  "company-enums.complaint_reference": () => company_enums_complaint_reference,
  "company-enums.complaint_stage": () => company_enums_complaint_stage,
  "company-enums.complaint_status": () => company_enums_complaint_status,
  "company-enums.ramos": () => company_enums_ramos,
  "company-service": () => company_service,
  complaint: () => complaint,
  contact: () => contact,
  contract: () => contract,
  cost: () => cost,
  coverages: () => coverages,
  currencies: () => currencies,
  damages: () => damages,
  "damages.material.real_property": () => damages_material_real_property,
  "damages.material.vehicle": () => damages_material_vehicle,
  default: () => es_default,
  delta: () => delta,
  distribution: () => distribution,
  document: () => document,
  "driver-ownership": () => driver_ownership,
  entities: () => entities,
  entrypoint: () => entrypoint,
  entrypoints: () => entrypoints,
  errors: () => errors,
  "estimates-definition": () => estimates_definition,
  events: () => events,
  "exported-report": () => exported_report,
  field_mapping_config: () => field_mapping_config,
  file_types: () => file_types,
  filters: () => filters,
  fractures: () => fractures,
  fraud: () => fraud,
  "fraud-investigation-case": () => fraud_investigation_case,
  frequency: () => frequency,
  "identification-type": () => identification_type,
  "inhouse-lawyer": () => inhouse_lawyer,
  injuries: () => injuries,
  inspection: () => inspection,
  legalPersonType: () => legalPersonType,
  legal_person: () => legal_person,
  license: () => license,
  load_balance_rule: () => load_balance_rule,
  "local-insured-relationships": () => local_insured_relationships,
  materialDamage: () => materialDamage,
  message_template: () => message_template,
  notifications: () => notifications,
  occurrence: () => occurrence,
  offer: () => offer,
  operators: () => operators,
  orders: () => orders,
  parents: () => parents,
  "payment-method": () => payment_method,
  permissions: () => permissions,
  person: () => person,
  professions: () => professions,
  real_property: () => real_property,
  resources: () => resources,
  rfp: () => rfp,
  role: () => role,
  severities: () => severities,
  sex: () => sex,
  sinister: () => sinister,
  sla: () => sla,
  stats: () => stats,
  "style-config": () => style_config,
  "target-group": () => target_group,
  "trigger-mappings": () => trigger_mappings,
  type: () => type,
  user: () => user,
  "validation-pattern": () => validation_pattern,
  validations: () => validations,
  variable: () => variable,
  vehicle: () => vehicle,
  "victim-injuries-roles": () => victim_injuries_roles,
  victim_relationship: () => victim_relationship,
  webhook: () => webhook,
  "working-days": () => working_days,
  zod: () => zod
});
var field_mapping_config = {
  entities: {
    claim: {
      label: "Reclamo"
    }
  },
  fields: {
    external_claim_request_id: {
      label: "Numero de tramite"
    },
    ref_id: {
      label: "ID de referencia"
    }
  }
};
var message_template = {
  roles: {
    automatic_response: "Respuesta autom\xE1tica del chat"
  },
  context_path: {
    claim: {
      ref_id: "ID de referencia",
      external_claim_id: "ID Reclamo Externo",
      id: "ID Reclamo",
      owner: {
        first_name: "Nombre Reclamante Usuario",
        last_name: "Apellido Reclamante Usuario",
        email: "Email Reclamante Usuario"
      },
      sinister: {
        selected_external_complaint: {
          external_id: "Numero de siniestro"
        },
        policyholder_license_plate: "Patente Asegurado"
      }
    },
    file_request_placeholder_list: "Lista de archivos solicitados"
  },
  scope: {
    offer: "Ofrecimiento",
    communication: "Comunicaci\xF3n"
  }
};
var stats = {
  modules: {
    claim: "Reclamos",
    complaint: "Denuncias"
  }
};
var chart = {
  frequencies: {
    daily: "Diario",
    weekly: "Semanal",
    monthly: "Mensual"
  }
};
var distribution = {
  categories: {
    worst: "peor",
    best: "mejor"
  }
};
var trigger_mappings = {
  actions: {
    file_request: "Solicitud de archivo",
    authorize_complaint: "Autorizar denuncia",
    approve_complaint: "Aprobar denuncia",
    reject_complaint: "Rechazar denuncia"
  },
  status: "Estado"
};
var sla = {
  form: {
    types: {
      stage: {
        title: "Etapas"
      },
      reference: {
        title: "Referencias"
      },
      status: {
        title: "Estados"
      }
    }
  },
  columns: {
    sla_expected_time_to_resolution: "Tiempo estipulado",
    sla_current_time_to_resolution: "Tiempo transcurrido",
    sla_type: "Tipo de SLA",
    sla_name: "Nombre de SLA",
    sla_elapsed_duration_criteria: "Criterio de duraci\xF3n",
    sla_version: "Versi\xF3n de SLA",
    relation_type_responsible: "Responsable",
    relation_type_coordinator: "Coordinador",
    relation_type_id: "{{relationTypeLabel}} ID",
    started_at: "Fecha inicio",
    deadline: "Fecha l\xEDmite",
    completed_at: "Fecha finalizaci\xF3n",
    status: "Estado",
    compliance: "Cumplimiento",
    progress: "Progreso",
    sla_objective_reference_name: "Nombre de objetivo"
  },
  metrics: {
    avg: "Promedio",
    max: "M\xE1ximo",
    min: "M\xEDnimo",
    median: "Mediana",
    percentile_25: "Percentil 25",
    percentile_50: "Percentil 50",
    percentile_75: "Percentil 75",
    percentile_80: "Percentil 80",
    percentile_90: "Percentil 90",
    trendline: "Tendencia"
  },
  commitments: {
    time: {
      singular: {
        days: "D\xEDa",
        hours: "Hora"
      },
      plural: {
        days: "D\xEDas",
        hours: "Horas"
      }
    },
    elapsed_duration_criteria: {
      singular: {
        working_days: "h\xE1bil",
        working_hours: "h\xE1bil"
      },
      plural: {
        working_days: "h\xE1biles",
        working_hours: "h\xE1biles"
      }
    }
  },
  virtual_metric: {
    time_to_first_response: {
      name: "Tiempo para primera respuesta",
      description: "Tiempo que se tarda en responder al primer mensaje recibido"
    },
    avg_response_time: {
      name: "Tiempo promedio de respuesta",
      description: "Tiempo promedio de respuesta luego del primer mensaje respondido"
    }
  },
  status: {
    completed: "Completado",
    active: "Activo",
    effective: "Vigente",
    planned: "Planificado",
    expired: "Expirado",
    deactivated: "Desactivado"
  },
  report: {
    status: {
      active: "Activo",
      completed: "Completado"
    },
    compliance: {
      on_time: "A tiempo",
      expired: "Vencido"
    }
  },
  elapsed_duration_criteria: {
    elapsed_days: "D\xEDas corridos",
    working_days: "D\xEDas h\xE1biles",
    elapsed_hours: "Horas corridas",
    working_hours: "Horas h\xE1biles"
  },
  scopes: {
    claim: "Reclamo",
    complaint: "Denuncia"
  },
  types: {
    stage: "Etapa",
    status: "Estado",
    reference: "Referencia",
    chat: "Mensajes"
  }
};
var working_days = {
  days: {
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Mi\xE9rcoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "S\xE1bado",
    sunday: "Domingo"
  }
};
var payment_method = {
  cash: "Contado",
  credit: "Cr\xE9dito"
};
var estimates_definition = {
  strategies: {
    coverage: "Por cobertura"
  },
  scopes: {
    complaint: "Denuncia"
  }
};
var person = {
  type: {
    natural_person: "Persona f\xEDsica",
    legal_person: "Persona jur\xEDdica"
  }
};
var entrypoint = {
  environments: {
    production: "Producci\xF3n",
    sandbox: "Sandbox"
  },
  backends: {
    tajy: "Sebaot"
  }
};
var target_group = {
  scopes: {
    derivation: "Derivaci\xF3n",
    assignment: "Asignaci\xF3n"
  }
};
var actions = {
  show: "Mostrar"
};
var operators = {
  equals: "="
};
var delta = {
  actions: {
    change: "Actualizaci\xF3n",
    remove: "Eliminaci\xF3n",
    create: "Definici\xF3n"
  }
};
var offer = {
  status: {
    expired: "Expirada",
    rejected: "Rechazado",
    archived: "Archivada",
    draft: "Borrador",
    pending: "Pendiente",
    started: "Iniciada",
    completed: "Completada",
    canceled: "Anulada",
    accepted: "Aceptado"
  },
  agreement: {
    status: {
      expired: "Expirada",
      rejected: "Rechazado",
      archived: "Archivada",
      draft: "Borrador",
      pending: "Pendiente",
      started: "Iniciada",
      completed: "Completada",
      cancelled: "Anulada"
    }
  },
  providers: {
    autosign: "Autosign"
  }
};
var orders = {
  status: {
    open: "Abierto",
    close: "Cerrado"
  },
  "sub-types": {
    assessment: "Peritaci\xF3n",
    repair: "Reparaci\xF3n",
    investigation: "Investigaci\xF3n",
    parts: "Repuestos"
  },
  types: {
    purchase: "Compra",
    service: "Servicio"
  }
};
var variable = {
  value: {
    reference: {
      judicial_amount_times_percentage_of_liability: "Monto Judicial x % de Responsabilidad"
    },
    type: {
      reference: "Referencia",
      literal: "Literal"
    }
  },
  types: {
    text: "Texto",
    numeric: "Num\xE9rico",
    select: "Selecci\xF3n",
    file: "Archivo"
  },
  determination: {
    compile_time: "Compilaci\xF3n",
    runtime: "En tiempo de ejecuci\xF3n"
  }
};
var inhouse_lawyer = {
  status: {
    pending: "Pendiente",
    registered: "Registrado"
  }
};
var load_balance_rule = {
  paths: {
    desired_support_location_state_municipality: "Partido",
    ramo: "Secci\xF3n",
    desired_support_location_state: "Estado",
    claim_type: "Tipo de reclamo",
    occurrence: {
      city: "Ciudad"
    }
  },
  types: {
    claim: "Reclamo",
    complaint: "Denuncia"
  }
};
var document = {
  group: {
    vehicle_damages: "da\xF1os al veh\xEDculo",
    documentation: "documentaci\xF3n"
  }
};
var circulation = {
  direction: {
    one_way: "\xDAnico",
    two_way: "Doble"
  }
};
var license = {
  category: {
    car: "Auto",
    motorcycle: "Moto",
    foreign: "Extranjero",
    other: "Otro"
  }
};
var contract = {
  status: {
    open: "Abierto",
    close: "Cerrado"
  }
};
var cost = {
  type: {
    one_time: "Un solo pago",
    monthly: "Mensual",
    yearly: "Anualmente"
  }
};
var bid = {
  line: {
    unit: {
      hour: "Hora",
      day: "Dia",
      unit: "Piezas",
      km: "Kil\xF3metros"
    },
    type: {
      labor: "Mano de obra",
      auto_part: "Auto-parte",
      material: "Material",
      shipping: "Env\xEDo"
    }
  }
};
var rfp = {
  status: {
    open: "Abierto",
    closed: "Cerrado",
    canceled: "Cancelado"
  },
  tab: {
    detail: "Detalle",
    localization: "Zona",
    vendors: "Proveedores",
    files: "Archivos"
  },
  mode: {
    streaming: "En cualquier momento",
    batch: "Al cierre"
  }
};
var contact = {
  type: {
    field_adjuster: "Liquidador/Perito",
    vendor: "Proveedor"
  }
};
var filters = {
  stage: "Etapa",
  system_status: "Condici\xF3n",
  company: "Empresa",
  claimed_amount: "Monto reclamado",
  zone: "Zona",
  coordinator: "Coordinador",
  team: "Equipo",
  relationship: "V\xEDnculo",
  ramo: "Ramo",
  sub_status: "Sub-Estado",
  responsible: "Responsable",
  reference: "Referencia",
  type: "Tipo",
  status: "Estado",
  claim_damage: "Da\xF1os"
};
var legal_person = {
  type: {
    hospital: "Hospital",
    worker_compensation_insurer: "ART",
    health_insurance_fund: "Obra social",
    insurer: "Aseguradora"
  }
};
var user = {
  override_permission: {
    can_create_judicial_amount: "Crear Monto Judicial",
    can_update_judicial_amount: "Actualizar Monto Judicial",
    can_create_administrative_amount: "Crear Monto Admin.",
    can_update_administrative_amount: "Actualizar Monto Admin.",
    can_create_percentage_of_liability: "Crear % de Responsabilidad",
    can_update_percentage_of_liability: "Actualizar % de Responsabilidad"
  },
  type: {
    system: "Sistema",
    third: "Tercero",
    insurer: "Aseguradora",
    policyholder: "Asegurado",
    backoffice: "Backoffice",
    inhouse_lawyer: "Abogado Interno",
    field_adjuster: "Liquidador/Perito",
    vendor: "Proveedor"
  }
};
var zod = {
  errors: {
    missing_variable_definition: "El mensaje contiene una referencia una variable inexistente",
    invalid_type: "Se esperaba {{expected}}, se recibi\xF3 {{received}}",
    invalid_type_received_undefined: "Requerido",
    invalid_literal: "Valor literal inv\xE1lido, se esperaba {{expected}}",
    unrecognized_keys: "Llave(s) no reconocida(s) en el objeto: {{- keys}}",
    invalid_union: "Entrada inv\xE1lida",
    invalid_union_discriminator: "Valor discriminador inv\xE1lido. Se esperaba {{- options}}",
    invalid_enum_value: "Valor inv\xE1lido. Se esperaba {{- options}}, se recibi\xF3 '{{received}}'",
    invalid_arguments: "Argumentos de funci\xF3n inv\xE1lidos",
    invalid_return_type: "Tipo de retorno de funci\xF3n inv\xE1lido",
    invalid_date: "Fecha inv\xE1lida",
    custom: "Entrada inv\xE1lida",
    invalid_intersection_types: "Valores de intersecci\xF3n no pudieron ser mezclados",
    not_multiple_of: "N\xFAmero debe ser m\xFAltiplo de {{multipleOf}}",
    not_finite: "N\xFAmero no puede ser infinito",
    invalid_string: {
      email: "{{validation}} inv\xE1lido",
      url: "{{validation}} inv\xE1lido",
      uuid: "{{validation}} inv\xE1lido",
      cuid: "{{validation}} inv\xE1lido",
      regex: "Inv\xE1lido",
      datetime: "{{validation}} inv\xE1lida",
      startsWith: 'Entrada inv\xE1lida: debe comenzar con "{{startsWith}}"',
      endsWith: 'Entrada inv\xE1lida: debe finalizar con "{{endsWith}}"'
    },
    too_small: {
      array: {
        exact: "La lista debe contener exactamente {{minimum}} elemento(s)",
        inclusive: "La lista debe contener al menos {{minimum}} elemento(s)",
        not_inclusive: "La lista debe contener m\xE1s de {{minimum}} elemento(s)"
      },
      string: {
        exact: "El texto debe contener exactamente {{minimum}} car\xE1cter(es)",
        inclusive: "El texto debe contener al menos {{minimum}} car\xE1cter(es)",
        not_inclusive: "El texto debe contener m\xE1s de {{minimum}} car\xE1cter(es)"
      },
      number: {
        exact: "El n\xFAmero debe ser exactamente {{minimum}}",
        inclusive: "El n\xFAmero debe ser mayor o igual a {{minimum}}",
        not_inclusive: "El n\xFAmero debe ser mayor que {{minimum}}"
      },
      set: {
        exact: "Entrada inv\xE1lida",
        inclusive: "Entrada inv\xE1lida",
        not_inclusive: "Entrada inv\xE1lida"
      },
      date: {
        exact: "La fecha debe ser exactamente {{- minimum, datetime}}",
        inclusive: "La fecha debe ser mayor o igual al {{- minimum, datetime}}",
        not_inclusive: "La fecha debe ser mayor que el {{- minimum, datetime}}"
      }
    },
    too_big: {
      array: {
        exact: "La lista debe contener exactamente {{maximum}} elemento(s)",
        inclusive: "La lista debe contener como m\xE1ximo {{maximum}} elemento(s)",
        not_inclusive: "La lista debe contener menos que {{maximum}} elemento(s)"
      },
      string: {
        exact: "El texto debe contener exactamente {{maximum}} car\xE1cter(es)",
        inclusive: "El texto debe contener como m\xE1ximo {{maximum}} car\xE1cter(es)",
        not_inclusive: "El texto debe contener menos de {{maximum}} car\xE1cter(es)"
      },
      number: {
        exact: "El n\xFAmero debe ser exactamente {{maximum}}",
        inclusive: "El n\xFAmero debe ser menor o igual a {{maximum}}",
        not_inclusive: "El n\xFAmero debe ser menor que {{maximum}}"
      },
      set: {
        exact: "Entrada inv\xE1lida",
        inclusive: "Entrada inv\xE1lida",
        not_inclusive: "Entrada inv\xE1lida"
      },
      date: {
        exact: "La fecha debe ser exactamente {{- maximum, datetime}}",
        inclusive: "La fecha debe ser menor o igual al {{- maximum, datetime}}",
        not_inclusive: "La fecha debe ser menor que el {{- maximum, datetime}}"
      }
    }
  },
  validations: {
    email: "correo",
    url: "enlace",
    uuid: "uuid",
    cuid: "cuid",
    regex: "expresi\xF3n regular",
    datetime: "fecha"
  },
  types: {
    function: "funci\xF3n",
    number: "n\xFAmero",
    string: "texto",
    nan: "valor no n\xFAmerico",
    integer: "entero",
    float: "decimal",
    boolean: "booleano",
    date: "fecha",
    bigint: "entero grande",
    undefined: "indefinido",
    symbol: "s\xEDmbolo",
    null: "nulo",
    array: "lista",
    object: "objeto",
    unknown: "desconocido",
    promise: "promesa",
    void: "void",
    never: "nunca",
    map: "mapa",
    set: "conjunto"
  }
};
var inspection = {
  verdict: {
    approved: "Aprobado",
    disapproved: "Desaprobado",
    not_defined: "No definido",
    not_provided: "Sin resultado"
  },
  type: {
    bike: "Bicicleta",
    car: "Auto",
    moto: "Moto",
    people: "Persona",
    goods: "Bienes",
    machinery: "Maquinaria",
    custom: "Personalizado"
  },
  status: {
    created: "Creado",
    started: "Empezado",
    completed: "Completado",
    blocked: "Bloqueado",
    processing: "Procesando"
  },
  consumer_type: {
    policyholder: "Asegurado",
    claimant: "Tercero"
  }
};
var professions = {
  lawyer: "Abogado",
  other: "Otro"
};
var exported_report = {
  types: {
    sla_complaint: "sla-denuncias",
    sla_claim: "sla-reclamos",
    claim: "reclamos"
  }
};
var claims = {
  offer: {
    status: {
      pending: "Pendiente",
      accepted: "Aceptado",
      rejected: "Rechazado",
      canceled: "Anulado",
      expired: "Expirado"
    }
  },
  columns: {
    complaint_registered_by: "Nombre Creador Denuncia",
    responsible_email: "Email Responsable",
    responsible_name: "Nombre Responsable",
    claimed_amount: "Monto reclamado",
    complaint_agency: "Agencia Denuncia",
    complaint_external_id: "N. Siniestro de Denuncia",
    complaint_external_status: "Estado Denuncia",
    complaint_handler_email: "Email Tramitador Denuncia",
    complaint_handler_name: "Nombre Tramitador Denuncia",
    complaint_location: "Ubicaci\xF3n Denuncia",
    complaint_occurrence_date: "Fecha Ocurrencia Denuncia",
    complaint_registered_at: "Fecha Registro Denuncia",
    created_at: "Fecha Creaci\xF3n Reclamo",
    created_by_email: "Email Creador Reclamo",
    created_by_name: "Nombre Creador Reclamo",
    declared_damages: "Da\xF1os Declarados",
    desired_support_location: "Ubicaci\xF3n Reclamo",
    fraud_close_motive: "Motivo Cierre Investigaci\xF3n Fraude",
    fraud_created_at: "Fecha Creaci\xF3n Investigaci\xF3n Fraude",
    fraud_description: "Descripci\xF3n Investigaci\xF3n Fraude",
    fraud_sinister_number: "N. Siniestro Investigaci\xF3n Fraude",
    fraud_external_status: "Estado Investigaci\xF3n Fraude",
    fraud_instance: "Instancia Investigaci\xF3n Fraude",
    fraud_reason: "Motivo Investigaci\xF3n Fraude",
    fraud_report_accepted_by_email: "Email Aceptador Reporte Investigaci\xF3n Fraude",
    fraud_report_accepted_by_name: "Nombre Aceptador Reporte Investigaci\xF3n Fraude",
    fraud_report_conclusion: "Conclusi\xF3n Reporte Investigaci\xF3n Fraude",
    fraud_report_lab: "Estudio Reporte Investigaci\xF3n Fraude",
    fraud_responsible_email: "Email Responsable Investigaci\xF3n Fraude",
    fraud_responsible_name: "Nombre Responsable Investigaci\xF3n Fraude",
    fraud_result: "Resultado Investigaci\xF3n Fraude",
    fraud_sinister_type: "Tipo Siniestro Investigaci\xF3n Fraude",
    fraud_external_id: "N. Investigaci\xF3n Fraude",
    id: "N. Reclamo",
    occurrence_address: "Direcci\xF3n Ocurrencia",
    occurrence_city: "Ciudad Ocurrencia",
    occurrence_state: "Provincia Ocurrencia",
    occurrence_country: "Pa\xEDs Ocurrencia",
    occurrence_description: "Descripci\xF3n Ocurrencia",
    occurrence_sinister_date: "Fecha Ocurrencia",
    occurrence_zip_code: "C\xF3digo Postal Ocurrencia",
    policyholder_license_plate: "Patente Asegurado",
    policyholder_policy_number: "N. P\xF3liza Asegurado",
    ramo: "Ramo",
    reference: "Referencia",
    status: "Estado",
    victim_type: "Tipo de v\xEDctima",
    victim_city: "Ciudad V\xEDctima",
    victim_identification_type: "Tipo de documento V\xEDctima",
    victim_identification_value: "N. Documento V\xEDctima",
    victim_name: "Nombre V\xEDctima"
  },
  "delegate-strategies": {
    users: "Usuarios",
    team: "Equipo"
  },
  "derive-strategies": {
    user: "Usuario",
    team: "Equipo"
  }
};
var notifications = {
  scopes: {
    unread: "Sin leer",
    read: "Le\xEDdo",
    all: "Todas"
  },
  view: {
    true: "Le\xEDdo"
  }
};
var fraud = {
  conclusions: {
    positive: "Positivo",
    negative: "Negativo"
  },
  results: {
    resignation: "Resignaci\xF3n",
    surrender: "Desistimiento",
    negative: "Negativo",
    fraud: "Fraude",
    positive: "Positivo",
    elements_for_rejection: "Elementos para rechazo",
    in_extension: "En ampliaci\xF3n",
    canceled: "Cancelado",
    possible_prescription: "Posible prescripci\xF3n",
    without_result: "Sin Definir",
    not_derived: "No se deriva"
  }
};
var breadcrumb = {
  contract: "Contratos",
  request: "Licitaciones",
  fraud: "Investigaci\xF3n de fraude",
  complaint: "Denuncia",
  sinister: "Siniestro",
  claim: "Reclamos",
  inspection: "Inspecci\xF3n"
};
var complaint = {
  attributes: {
    is_recoverable: {
      label: "Recuperable",
      value: {
        true: "S\xED",
        false: "No"
      }
    },
    requires_spare_parts: {
      label: "Requiere repuestos",
      value: {
        true: "S\xED",
        false: "No"
      }
    }
  },
  "system-status": {
    open: "Abierto",
    closed: "Cerrado"
  },
  reference: {
    on_claim: "Generaci\xF3n de Reclamo",
    technical_analysis: "An\xE1lisis T\xE9cnico",
    inspection: "Inspecci\xF3n",
    responsibility_analysis: "An\xE1lisis de Responsabilidad",
    negotiation: "Negociaci\xF3n",
    payment_process: "En proceso de Pago",
    homologation: "Homologaci\xF3n",
    demand_transfer: "Traslado Demanda",
    canceled: "Anulado",
    rejected: "Rechazado (luego del An\xE1lisis)",
    settlement_payment: "Liquidaci\xF3n y Pago",
    mediation: "Mediaci\xF3n",
    judgment: "Juicio",
    fraud: "Indicio de Fraude"
  },
  role: {
    default: "Default",
    documentation_completed: "Documentaci\xF3n completa",
    documentation_request: "Documentaci\xF3n requerida",
    criminal_case_request: "Solicitud causa penal",
    offer_flow: "Ofrecimiento",
    not_response: "Sin respuesta",
    claim_rejected: "Rechazo",
    additional_management: "Gestiones adicionales"
  },
  types: {
    vehicle: "Veh\xEDculo",
    building: "Edificio",
    glass: "Cristales",
    medical_assistance_accident: "Asistencia medica por accidente",
    electronic_equipment: "Equipos electr\xF3nicos",
    theft: "Robo",
    other: "Otro"
  },
  tabs: {
    orders: "Ordenes",
    estimates: "Estimaciones",
    offers: "Ofrecimiento",
    "evaluation/reports": "Evaluacion",
    rfps: "Licitaciones",
    activity: "Actividad",
    documents: "Documentaci\xF3n",
    detail: "Detalle",
    inspections: "Inspecciones",
    inspection: "Inspecciones"
  },
  steps: {
    "vehicle-identification-key": "\xBFEl veh\xEDculo cuenta con N\xFAmero de Chapa?",
    "policyholder-type": "\xBFEs usted una persona f\xEDsica o jur\xEDdica?",
    "policyholder-info": "Informaci\xF3n del asegurado",
    "ask-police-intervention": "\xBFHubo intervenci\xF3n policial?",
    "complaint-type": "Tipo de denuncia",
    "occurrence-sinister": "Datos del siniestro",
    "third-party-info": "Datos del tercero involucrado",
    "is-third-party-involved": "\xBFHay un tercero involucrado?",
    "has-damage-budget": "\xBFYa cuentas con alg\xFAn presupuesto?",
    "did-third-party-flee": "\xBFEl tercero se dio a la fuga?",
    "vehicle-driver-info": "Datos del conductor",
    "policyholder-vehicle-info": "Datos vehiculares del asegurado",
    "current-email": "Email actual",
    welcome: "Bienvenido \u{1F44B}",
    "insured-info": "Informaci\xF3n del asegurado",
    "driver-detail": "Detalle del conductor",
    "insured-type": "Tipo de asegurado",
    "third-party-exists": "\xBFExiste un tercero?",
    "third-party-vehicle-type": "Tipo de veh\xEDculo del tercero",
    "third-party-detail": "Detalle del tercero",
    occurrence: "Siniestro",
    "complaint-confirmation": "Resumen",
    "complaint-other-details": "Detalle del riesgo",
    "doc-load": "Carga de documentos"
  },
  status: {
    role: {
      default: "Default"
    },
    open: "Abierto",
    closed: "Cerrado",
    reference: {
      claimant_disinterest: "Desinter\xE9s del Reclamante",
      not_successful: "No Prosper\xF3",
      reconciled: "Conciliado",
      in_closing_process: "En proceso de Cierre",
      derivation: "Derivaci\xF3n",
      analysis: "An\xE1lisis del reclamo",
      on_claim: "Generaci\xF3n de Reclamo",
      technical_analysis: "An\xE1lisis T\xE9cnico",
      inspection: "Inspecci\xF3n",
      responsibility_analysis: "An\xE1lisis de Responsabilidad",
      negotiation: "Negociaci\xF3n",
      payment_process: "En proceso de Pago",
      homologation: "Homologaci\xF3n",
      demand_transfer: "Traslado Demanda",
      canceled: "Anulado",
      rejected: "Rechazado (luego del An\xE1lisis)",
      settlement_payment: "Liquidaci\xF3n y Pago",
      mediation: "Mediaci\xF3n",
      judgment: "Juicio",
      fraud: "Indicio de Fraude"
    }
  },
  "delegate-strategies": {
    users: "Usuarios",
    team: "Equipo"
  }
};
var validation_pattern = {
  extractors: {
    rivadavia: "Rivadavia",
    tajy: "Tajy"
  },
  columns: {
    policy_number: "Numero de p\xF3liza"
  }
};
var frequency = {
  monthly: "Mensual",
  daily: "Diario"
};
var claimants = {
  natural_person: "Persona f\xEDsica",
  legal_person: "Persona jur\xEDdica"
};
var claim = {
  responsible: {
    group: {
      current_team_membership: "Miembros del equipo",
      prev_responsible: "Responsables anteriores",
      team_coordinator: "Equipos derivables"
    }
  },
  sinisterTypes: {
    policyholder: {
      text: "Mi siniestro no fue con un veh\xEDculo asegurado en {{companyName}}, pero s\xED con un asegurado"
    },
    policyholder_vehicle: {
      text: "Tuve un siniestro con un veh\xEDculo asegurado en {{companyName}}"
    },
    policyholder_vehicle_foreign: {
      text: "Tuve un siniestro con un veh\xEDculo asegurado en el extranjero a qui\xE9n representa {{companyName}} en Argentina"
    }
  },
  "system-status": {
    open: "Abierto",
    closed: "Cerrado"
  },
  attributes: {
    damages: {
      fixed: {
        label: "\xBFFue arreglado?"
      },
      label: "Da\xF1os",
      material: {
        insurance: {
          label: "Seguro",
          coverage: {
            label: "Cobertura",
            value: {
              civil_responsibility: "Responsabilidad Civil",
              third_robbery_fire: "Tercero, Robo e incendio",
              third_full: "Tercero Completo",
              all_risks_without_franchise: "Todo Riesgo sin Franquicia",
              all_risks_with_franchise: "Todo Riesgo con Franquicia"
            }
          },
          insurer: {
            label: "Aseguradora"
          },
          policy_number: {
            label: "N\xFAmero de p\xF3liza"
          },
          franchise_amount: {
            label: "Monto de franquicia"
          }
        },
        label: "Materiales",
        vehicle: {
          type: {
            label: "Tipo",
            value: {
              bike: "Bicicleta",
              machinery: "Maquinaria",
              skateboard: "Monopat\xEDn",
              other: "Otro",
              car: "Auto",
              moto: "Moto"
            }
          },
          make: {
            label: "Marca del veh\xEDculo"
          },
          model: {
            label: "Modelo del veh\xEDculo"
          },
          year: {
            label: "A\xF1o del veh\xEDculo"
          },
          version: {
            label: "Versi\xF3n del veh\xEDculo"
          },
          market_value: {
            label: "Valor venal"
          },
          use: {
            label: "Uso del veh\xEDculo",
            value: {
              private: "Particular",
              public: "P\xFAblico",
              taxi: "Taxi",
              remis: "Remis",
              rental: "Alquiler de Autos",
              ambulance: "Ambulancia",
              police: "Patrullero",
              school: "Escuela",
              specialService: "Sev. Especial Plataforma Electron",
              special_service: "Sev. Especial Plataforma Electron",
              goods: "Transporte de mercader\xEDas",
              persons: "Transporte de personas",
              special_school_pickup: "Servicios Especiales y Escolares (Pick Up)",
              goods_transport: "Transporte de Bienes",
              local: "Locales",
              route_service: "Ruteros",
              school_transport: "Escolares",
              electronic_platform: "Servicio Especial Plataforma Electr\xF3nica",
              delivery_service: "Servicio de Reparto y Mensajer\xEDa",
              agency: "Agencias (Con/Sin Chofer c/ chapa Particular)"
            }
          },
          category: {
            label: "Categor\xEDa",
            value: {
              with_license_plate: "Veh\xEDculo con patente (Auto o Moto)",
              without_license_plate: "Veh\xEDculo sin patente (Bicicleta, Monopatines, Maquinaria, etc)"
            }
          },
          license_plate: {
            label: "Patente"
          },
          chassis: {
            label: "Chassis"
          },
          engine: {
            label: "Motor"
          },
          label: "Veh\xEDculo",
          driver: {
            ownership: {
              label: "Conductor Propiedad",
              value: {
                owner: "Due\xF1o",
                third: "Tercero"
              }
            },
            label: "Conductor",
            first_name: {
              label: "Conductor Nombre"
            },
            last_name: {
              label: "Conductor Apellido"
            },
            cellphone: {
              label: "Conductor Celular"
            }
          }
        }
      },
      person: {
        health_insurance_fund: {
          label: "Obra social"
        },
        surgery_description: {
          label: "Intervenci\xF3n quir\xFArgica"
        },
        treatment_medical_institution: {
          label: "Instituci\xF3n Medica"
        },
        treatment_worker_compensation_insurer: {
          label: "ART"
        },
        policyholder_relationship_other: {
          label: "Otro v\xEDnculo"
        },
        policyholder_relationship: {
          label: "Relaci\xF3n con el asegurado"
        },
        label: "Persona",
        victim_role: {
          label: "Condici\xF3n de",
          value: {
            pedestrian: "Peat\xF3n",
            driver: "Conductor del veh\xEDculo tercero",
            rider: "Transportado en el veh\xEDculo tercero",
            policyholder_rider: "Transportado en el veh\xEDculo asegurado en {{insurerName}}"
          }
        }
      }
    },
    claimants: {
      label: "Reclamante",
      victim_relationship_other: {
        label: "Otro v\xEDnculo"
      },
      victim_relationship: {
        label: "V\xEDnculo con damnificado",
        value: {
          label: "Relaci\xF3n con el asegurado",
          mother: "Madre",
          father: "Padre",
          other_family_member: "Otro familiar",
          tutor_curator: "Tutor/Curador",
          attorney_in_fact: "Abogado Apoderado",
          legal_representative: "Apoderado legal",
          attorney_representative: "Abogado patrocinante",
          other: "Otro",
          brother: "Hermano",
          producer: "Productor",
          workshopper: "Tallerista",
          own: "Reclamo en nombre propio",
          literate: "Letrado",
          particular_victim: "Damnificado particular"
        }
      },
      natural_person: {
        parents: {
          label: "Padres",
          first_name: {
            label: "Nombre"
          },
          last_name: {
            label: "Apellido"
          },
          identification: {
            label: "Documento"
          },
          type: {
            label: "Tipo",
            value: {
              father: "Padre",
              mother: "Madre"
            }
          }
        },
        label: "Persona F\xEDsica",
        first_name: {
          label: "Nombre"
        },
        last_name: {
          label: "Apellido"
        },
        birth_date: {
          label: "Fecha de nacimiento"
        },
        sex: {
          label: "Sexo"
        },
        identification_type: {
          label: "Tipo de documento"
        },
        identification_value: {
          label: "Documento"
        },
        nationality: {
          label: "Nacionalidad"
        },
        state: {
          label: "Provincia"
        },
        city: {
          label: "Ciudad"
        },
        address: {
          label: "Direcci\xF3n"
        },
        zip_code: {
          label: "C\xF3digo postal"
        }
      }
    },
    victim: {
      label: "Damnificado",
      natural_person: {
        cellphone: {
          label: "Celular"
        },
        marital_status: {
          label: "Estado civil",
          value: {
            single: "Soltero",
            married: "Casado",
            widowed: "Viudo",
            divorced: "Divorciado",
            common_law: "Union de hecho",
            separated: "Separado"
          }
        },
        parents: {
          label: "Padres",
          first_name: {
            label: "Nombre"
          },
          last_name: {
            label: "Apellido"
          },
          identification: {
            label: "Documento"
          },
          type: {
            label: "Tipo",
            value: {
              father: "Padre",
              mother: "Madre"
            }
          }
        },
        label: "Persona F\xEDsica",
        first_name: {
          label: "Nombre"
        },
        last_name: {
          label: "Apellido"
        },
        birth_date: {
          label: "Fecha de nacimiento"
        },
        sex: {
          label: "Sexo",
          value: {
            male: "Masculino",
            female: "Femenino",
            other: "Otro"
          }
        },
        identification_type: {
          label: "Tipo de documento",
          value: {
            national_id_document: "DNI",
            enrollment_book: "Libreta Enrolamiento",
            civil_registry_card: "Libreta C\xEDvica",
            passport: "Pasaporte",
            cuil: "CUIL",
            cuit: "CUIT"
          }
        },
        identification_value: {
          label: "Documento"
        },
        nationality: {
          label: "Nacionalidad"
        },
        state: {
          label: "Provincia"
        },
        city: {
          label: "Ciudad"
        },
        address: {
          label: "Direcci\xF3n"
        },
        zip_code: {
          label: "C\xF3digo postal"
        }
      },
      victim_relationship: {
        label: ""
      }
    },
    owner: {
      label: "Usuario reclamante",
      username: {
        label: "Nombre de usuario"
      },
      email: {
        label: "Correo"
      },
      first_name: {
        label: "Nombre"
      },
      last_name: {
        label: "Apellido"
      },
      cellphone: {
        label: "Celular"
      },
      identification: {
        label: "Identificaci\xF3n"
      },
      sex: {
        label: "Sexo"
      }
    },
    created_by: {
      label: "Usuario creador",
      username: {
        label: "Nombre de usuario"
      },
      email: {
        label: "Correo"
      },
      first_name: {
        label: "Nombre"
      },
      last_name: {
        label: "Apellido"
      },
      cellphone: {
        label: "Celular"
      },
      identification: {
        label: "Identificaci\xF3n"
      },
      sex: {
        label: "Sexo"
      }
    },
    sinister: {
      label: "Siniestro",
      date: {
        label: "Fecha del siniestro"
      },
      license_plate: {
        label: "Patente del asegurado"
      },
      external_complaint: {
        label: "Denuncia",
        date: {
          label: "Fecha"
        },
        agency: {
          label: "Agencia"
        },
        external_id: {
          label: "Numero de siniestro"
        },
        external_status: {
          label: "Estado"
        },
        occurrence_date: {
          label: "Fecha de la denuncia"
        },
        registered_by: {
          label: "Registrado por"
        },
        policyholder: {
          label: "Asegurado",
          policy_number: {
            label: "N\xFAmero de p\xF3liza"
          },
          agency: {
            label: "Agencia"
          },
          order: {
            label: "Orden"
          },
          cuil: {
            label: "CUIL"
          },
          address: {
            label: "Direcci\xF3n"
          },
          city: {
            label: "Ciudad"
          },
          country: {
            label: "Pa\xEDs"
          },
          make: {
            label: "Marca"
          },
          model: {
            label: "Modelo"
          },
          identification: {
            label: "Identificaci\xF3n"
          },
          first_name: {
            label: "Nombre"
          },
          last_name: {
            label: "Apellido"
          },
          sex: {
            label: "Sexo"
          },
          landline_phone: {
            label: "Tel\xE9fono"
          },
          marital_status: {
            label: "Estado civil"
          },
          license_plate: {
            label: "Patente"
          },
          plan: {
            label: "Plan"
          },
          use: {
            label: "Uso"
          },
          yer: {
            label: "A\xF1o"
          },
          zip_code: {
            label: "C\xF3digo postal"
          }
        }
      }
    },
    license_plate: {
      label: "Patente del asegurado"
    },
    occurrence: {
      label: "Ocurrencia",
      address: {
        label: "Direcci\xF3n"
      },
      time: {
        label: "Hora"
      },
      witnesses_info: {
        label: "Informaci\xF3n de los testigos"
      },
      police_intervention: {
        label: "Intervenci\xF3n Policial"
      },
      police_station: {
        label: "Estaci\xF3n policial"
      },
      criminal_case_number: {
        label: "N\xFAmero causa penal"
      },
      judicial_department: {
        label: "Departamento judicial"
      },
      clerks_office: {
        label: "N\xFAmero de Secretaria"
      },
      court: {
        label: "N\xFAmero de Juzgado"
      },
      country: {
        label: "Pa\xEDs"
      },
      city: {
        label: "Ciudad"
      },
      zip_code: {
        label: "C\xF3digo Postal"
      },
      description: {
        label: "Descripci\xF3n"
      },
      state: {
        label: "Provincia"
      },
      damage_description: {
        label: "Descripci\xF3n de los da\xF1os"
      },
      sinister_date: {
        label: "Fecha del siniestro"
      }
    }
  },
  estimates: {
    vehicle_market_value: "Valor Venal",
    person_percentage_of_judicial_risk: "Riesgo Judicial",
    administrative_amount: "Monto Administrativo",
    judicial_amount: "Monto Judicial",
    percentage_of_liability: "Porcentaje de Responsabilidad",
    material_judicial_amount: "Da\xF1o Material Monto Judicial",
    person_judicial_amount: "Fallecidos Lesionados Monto Judicial",
    material_administrative_amount: "Da\xF1o Material Monto Administrativo",
    person_administrative_amount: "Fallecidos Lesionados Monto Administrativo"
  },
  type: {
    material_vehicle_with_license_plate: "Da\xF1os Materiales de veh\xEDculos con patente",
    material: "Da\xF1os Materiales",
    material_vehicle_without_license_plate: "Da\xF1os Materiales de veh\xEDculos sin patente",
    person_injury: "Lesiones",
    material_and_person_injury: "Combinado con Lesiones",
    person_death: "Fallecidos",
    material_real_property: "Da\xF1os materiales a inmuebles",
    claimant_worker_compensation_insurer: "ART",
    material_and_person_death: "Da\xF1os Materiales y Fallecidos"
  },
  status: {
    open: "Abierto",
    closed: "Cerrado",
    reference: {
      claimant_disinterest: "Desinter\xE9s del Reclamante",
      not_successful: "No Prosper\xF3",
      reconciled: "Conciliado",
      in_closing_process: "En proceso de Cierre",
      derivation: "Derivaci\xF3n",
      analysis: "An\xE1lisis del reclamo",
      on_claim: "Generaci\xF3n de Reclamo",
      technical_analysis: "An\xE1lisis T\xE9cnico",
      inspection: "Inspecci\xF3n",
      responsibility_analysis: "An\xE1lisis de Responsabilidad",
      negotiation: "Negociaci\xF3n",
      payment_process: "En proceso de Pago",
      homologation: "Homologaci\xF3n",
      demand_transfer: "Traslado Demanda",
      canceled: "Anulado",
      rejected: "Rechazado (luego del An\xE1lisis)",
      settlement_payment: "Liquidaci\xF3n y Pago",
      mediation: "Mediaci\xF3n",
      judgment: "Juicio",
      fraud: "Fraude"
    },
    role: {
      default: "Por defecto",
      documentation_completed: "Documentaci\xF3n completa",
      documentation_request: "Documentaci\xF3n requerida",
      criminal_case_request: "Solicitud causa penal",
      offer_flow: "Ofrecimiento",
      not_response: "Sin respuesta",
      claim_rejected: "Rechazo",
      additional_management: "Gestiones adicionales",
      default_missing_documentation: "Por defecto - Documentaci\xF3n faltante",
      claim_derived_user_strategy: "Reclamo derivado a usuario",
      claim_derived_team_strategy: "Reclamo derivado a grupo de destino",
      offer_agreement_completed: "Conformidad completada",
      offer_accepted: "Ofrecimiento aceptado"
    }
  },
  tabs: {
    detail: "Detalle",
    documents: "Documentaci\xF3n",
    claims: "Reclamos",
    activity: "Actividad",
    fraud: "Fraude",
    complaints: "Denuncias"
  },
  steps: {
    "doc-proof_of_ownership_document": "Certificado de propiedad",
    "doc-vehicle_title": "Titulo del veh\xEDculo",
    "doc-victim_national_identity_card_back": "DNI (dorso)",
    "doc-victim_national_identity_card_front": "DNI (frente)",
    "doc-policy_insurance": "Copia completa de la p\xF3liza",
    "doc-repair_bill": "Factura de reparaci\xF3n",
    "doc-damage_budget": "Presupuesto de los da\xF1os",
    "doc-real_property": "Fotos de la propiedad",
    "doc-vehicle": "Fotos del veh\xEDculo",
    "doc-rental_agreement": "Contrato de alquiler",
    "doc-deed": "Escritura",
    "doc-subrogation_certificate": "Constancia de subrogaci\xF3n",
    "doc-expense_receipt": "Factura/Comprobante por gastos medicos",
    "doc-medical_report": "Dictamen medico",
    "doc-medical_discharge": "Alta definitiva",
    "doc-accounting_certificate": "Certificado contable",
    "doc-policy_deductible_letter": "Carta de franquicia",
    "doc-policy_coverage_certificate": "Certificado de cobertura",
    "doc-inspection_photo": "Foto de Inspecci\xF3n",
    "doc-orion_report": "Informe Orion",
    "doc-sinister_video": "Video del siniestro",
    "doc-sinister_photo": "Foto del siniestro",
    "doc-vehicle_id_card_back": "C\xE9dula verde (dorso)",
    "doc-vehicle_id_card_front": "C\xE9dula verde (frente)",
    "doc-mother_national_id_document_front": "DNI de la madre (frente)",
    "doc-mother_national_id_document_back": "DNI de la madre (dorso)",
    "doc-father_national_id_document_back": "DNI del padre (dorso)",
    "doc-father_national_id_document_front": "DNI del padre (frente)",
    "doc-income_verification": "Acreditaci\xF3n de ingresos",
    "doc-occupancy_report": "Informe de ocupaci\xF3n",
    "doc-death_certificate": "Acta de defunci\xF3n",
    "doc-birth_certificate": "Partida de nacimiento",
    "doc-certificate_medical_treatment": "Certificado atenci\xF3n medica",
    "doc-affidavit_of_no_insurance": "Declaraci\xF3n jurada de no seguro",
    "doc-insurance_complaint": "Denuncia a la aseguradora",
    "doc-complaint": "Denuncia",
    "doc-load": "Carga de documentos",
    "doc-claim-note": "Escrito de reclamo",
    "claim-created": "Reclamo creado",
    "claimant-list": "Lista de reclamantes",
    "medical-institution-info": "Datos de la instituci\xF3n m\xE9dica",
    "worker-compensation-insurer-info": "Datos de la ART",
    "health-insurance-info": "Datos de la obra social",
    "was-treated-by-medical-institution": "Atenci\xF3n medica",
    "had-surgery": "Intervenci\xF3n quir\xFArgica",
    "surgery-description": "Intervenci\xF3n quir\xFArgica",
    "was-treated-by-worker-compensation-insurer": "Servicio de ART",
    "has-health-insurance": "Obra social",
    "victim-minor-mother-info": "Datos de la madre",
    "victim-minor-father-info": "Datos del padre",
    "victim-injuries-details": "Detalles de lesiones",
    "victim-injuries-rol": "Datos del damnificado",
    "vehicle-ownership-document-choose": "Autenticaci\xF3n del veh\xEDculo",
    "real-property-info": "Informaci\xF3n del Inmueble",
    "real-property-location": "Ubicaci\xF3n del Inmueble",
    "match-real-property-victim-address": "Coincidencia de domicilio",
    "location-info": "Ubicaci\xF3n del reclamo",
    "claimed-amount": "Monto reclamado",
    "sinister-info": "Datos del siniestro",
    "sinister-type": "Tipo de siniestro",
    "vehicle-driver-info": "Datos del conductor",
    "material-damage-insurer-info": "Datos del seguro",
    "vehicle-info": "Datos del veh\xEDculo",
    "legal-person-reimbursements-info": "Datos de la instituci\xF3n reclamante",
    "vehicle-driver-ownership": "Propiedad del veh\xEDculo",
    "victim-natural-person-info": "Datos del Damnificado (persona f\xEDsica)",
    "victim-natural-person-address-info": "Domicilio Damnificado (persona f\xEDsica)",
    "victim-relationship": "V\xEDnculo con el damnificado",
    "victim-legal-person-info": "Damnificado (persona jur\xEDdica)",
    "recovery-institution-info": "Instituci\xF3n Reclamante",
    "own-claim-choose": "Damnificado, \xBFReclama en nombre propio?",
    "victim-type-choose": "Damnificado",
    "damage-declaration": "Selecciona los da\xF1os que deseas declarar.",
    "damage-material-type": "\xBFQue tipo de da\xF1o material sufri\xF3?",
    "special-branch-info": "Reporte del siniestro",
    "is-local-insured-involved": "\xBFEl siniestro involucr\xF3 un veh\xEDculo asegurado por {{companyName}}?",
    "vehicle-category": "Tipo de veh\xEDculo",
    "claim-confirmation": "Env\xEDo de reclamo",
    welcome: "Bienvenido",
    "insured-info": "Reporte del siniestro vehicular",
    "claimant-info": "Reclamante",
    "has-insurance": "\xBFPose\xEDa seguro al momento del siniestro?",
    "has-income-verification": "\xBFPosee la acreditaci\xF3n de ingresos del damnificado?",
    "occurrence-optional-info": "Datos de denuncia"
  },
  document: {
    police_report: "Parte policial",
    identity_card: "C\xE9dula de identidad",
    incident_damage_photo: "Foto del incidente",
    vehicle_roof: "Veh\xEDculo Parte Techo",
    vehicle_side_right: "Veh\xEDculo Parte Acompa\xF1ante",
    vehicle_side_left: "Veh\xEDculo Parte Conductor",
    vehicle_front: "Veh\xEDculo Parte Frontal",
    vehicle_back: "Veh\xEDculo Parte Trasera",
    vehicle_damage_photo_bonnet: "Veh\xEDculo - Da\xF1o Capot",
    vehicle_damage_photo_boot: "Veh\xEDculo - Da\xF1o Trasero",
    vehicle_damage_photo_door_left: "Veh\xEDculo - Da\xF1o Puerta Izquierda",
    vehicle_damage_photo_door_right: "Veh\xEDculo - Da\xF1o Puerta Derecha",
    vehicle_damage_photo_roof: "Veh\xEDculo - Da\xF1o Techo",
    vehicle_qualification: "Veh\xEDculo - Habilitacion",
    policy_holder_vehicle_identity_card_front: "C\xE9dula verde (frente)",
    policy_holder_vehicle_identity_card_back: "C\xE9dula verde (dorso)",
    policy_holder_identity_card_front: "C\xE9dula de identidad (frente)",
    policy_holder_identity_card_back: "C\xE9dula de identidad (dorso)",
    vehicle_authorization_document: "Veh\xEDculo - Habilitaci\xF3n",
    vehicle_authorization_document_front: "Veh\xEDculo - Habilitaci\xF3n (frente)",
    vehicle_authorization_document_back: "Veh\xEDculo - Habilitaci\xF3n (dorso)",
    vehicle_registration_card: "C\xE9dula del veh\xEDculo",
    vehicle_registration_card_front: "C\xE9dula del veh\xEDculo (frente)",
    vehicle_registration_card_back: "C\xE9dula del veh\xEDculo (dorso)",
    driver_license: "C\xE9dula de conducir",
    policy_holder_driver_license_front: "C\xE9dula de conducir (frente)",
    policy_holder_driver_license_back: "C\xE9dula de conducir (dorso)",
    policy_coverage_certificate: "Certificado de cobertura",
    policy_deductible_letter: "Carta de franquicia",
    accounting_certificate: "Certificado contable",
    medical_discharge: "Alta definitiva",
    medical_report: "Dictamen medico",
    expense_receipt: "Factura/Comprobante por gastos medicos",
    subrogation_certificate: "Constancia de subrogaci\xF3n",
    deed: "Escritura",
    appraisal_invoice: "Factura del liquidador/perito",
    contract_invoice: "Factura del contratista",
    rental_agreement: "Contrato de alquiler",
    vehicle: "Fotos del veh\xEDculo",
    real_property: "Fotos de la propiedad",
    damage_budget: "Presupuesto de los da\xF1os",
    repair_bill: "Factura de reparaci\xF3n",
    policy_insurance: "Copia completa de la p\xF3liza",
    victim_national_identity_card_front: "DNI del damnificado (frente)",
    victim_national_identity_card_back: "DNI del damnificado (dorso)",
    vehicle_title: "Titulo del veh\xEDculo",
    proof_of_ownership_document: "Certificado de propiedad",
    complaint: "Denuncia",
    insurance_complaint: "Denuncia a la aseguradora",
    affidavit_of_no_insurance: "Declaraci\xF3n jurada de no seguro",
    certificate_medical_treatment: "Certificado atenci\xF3n medica",
    birth_certificate: "Partida de nacimiento",
    death_certificate: "Acta de defunci\xF3n",
    occupancy_report: "Informe de ocupaci\xF3n",
    income_verification: "Acreditaci\xF3n de ingresos",
    father_national_id_document_front: "DNI del padre (frente)",
    father_national_id_document_back: "DNI del padre (dorso)",
    mother_national_id_document_front: "DNI de la madre (frente)",
    mother_national_id_document_back: "DNI de la madre (dorso)",
    vehicle_id_card_front: "C\xE9dula verde (frente)",
    vehicle_id_card_back: "C\xE9dula verde (dorso)",
    sinister_photo: "Foto del siniestro",
    sinister_video: "Video del siniestro",
    orion_report: "Informe Orion",
    inspection_photo: "Foto de Inspecci\xF3n",
    expert_appraisal_photo: "Foto de Peritaci\xF3n",
    offer_agreement: "Ofrecimiento de Indemnizaci\xF3n",
    appraisal_report: "Informe de evaluaci\xF3n",
    contract_report: "Reporte de contrato",
    appraisal_updates_file: "Novedades de la evaluaci\xF3n",
    contract_updates_file: "Novedades del contrato",
    rfp_bid_file: "Licitaci\xF3n Proveedor Documento",
    rfp_contract_file: "Reporte Licitaci\xF3n Contrato",
    order_report: "Reporte de orden",
    order_invoice: "Factura de orden",
    vehicle_side_left_damage: "Da\xF1os en el lado conductor",
    vehicle_side_right_damage: "Da\xF1os en el lado acompa\xF1ante",
    vehicle_back_damage: "Da\xF1os en la parte trasera",
    vehicle_front_damage: "Da\xF1os en la parte frontal",
    vehicle_roof_damage: "Da\xF1os en el techo",
    signature: "Firma",
    claim_note: "Escrito de reclamo"
  }
};
var events = {
  complaint_collaborator_assigned: "Colaborador asignado",
  claim_collaborator_assigned: "Colaborador asignado",
  claim_activity_view: "Vista de actividad de reclamo",
  complaint_documentation_list_view: "Vista de documentaci\xF3n de denuncia",
  fraud_news_requested: "Solicitud de novedades en investigaci\xF3n de fraude",
  fraud_news_answered: "Novedades en investigaci\xF3n de fraude",
  complaint_responsible_assigned: "Responsable asignado",
  claim_responsible_assigned: "Responsable asignado",
  sinister_complaints_view: "Vista de denuncias",
  sinister_fraud_investigation_cases_view: "Vista de casos de investigaci\xF3n de fraude",
  sinister_expert_appraisals_view: "Vista de peritaciones",
  claim_form_step_completed: "Paso de formulario de reclamo completado",
  claim_form_step_view: "Vista de paso de formulario de reclamo",
  claim_form_navigation: "Navegaci\xF3n en formulario de reclamo",
  sinister_list_view: "Vista de siniestros",
  settings_profile_view: "Vista de perfil",
  documentation_filter: "Filtro de documentaci\xF3n",
  settings_distribution_view: "Vista de distribuci\xF3n",
  team_created: "Equipo creado",
  team_detail_view: "Vista de detalle de grupo de destino",
  load_balance_rule_created: "Regla de distribuci\xF3n creada",
  load_balance_rule_detail_view: "Vista de detalle de regla de distribuci\xF3n",
  teams_view: "Vista de equipos",
  invitations_view: "Vista de invitaciones",
  roles_view: "Vista de roles",
  load_balance_rules_view: "Vista de reglas de distribuci\xF3n",
  users_invited: "Usuarios invitados",
  forwarded_invitation: "Invitaci\xF3n reenviada",
  role_detail_view: "Vista de detalle de rol",
  claim_created: "Reclamo creado",
  documentation_search: "B\xFAsqueda de documentaci\xF3n",
  file_requested: "Solicitud de documentaci\xF3n creada",
  file_view: "Vista de documento",
  load_balance_rule_deleted: "Regla de distribuci\xF3n eliminada",
  load_balance_rule_updated: "Regla de distribuci\xF3n actualizada",
  login: "Inicio de sesi\xF3n",
  logout: "Cierre de sesi\xF3n",
  settings_notifications_view: "Vista de notificaciones",
  settings_team_view: "Vista de equipo",
  sinister_activity_view: "Vista de actividad de siniestro",
  files_requested: "Documentaci\xF3n solicitada",
  sinister_complaint_chat_message_send: "Denuncia - Mensaje de chat enviado",
  sinister_claim_chat_message_send: "Reclamo - Mensaje de chat enviado",
  sinister_claim_chat_view: "Vista de chat de reclamo",
  sinister_claim_detail_view: "Vista de detalle de reclamo",
  sinister_claim_view: "Vista del detalle de un reclamo",
  sinister_claims_view: "Vista de reclamos",
  sinister_collaborator_assigned: "Colaborador asignado",
  sinister_collaborator_unassigned: "Colaborador removido",
  sinister_document_view: "Vista de documento de siniestro",
  sinister_documentation_list_view: "Vista de documentaci\xF3n de siniestro",
  sinister_filter: "Filtro de siniestros",
  sinister_responsible_assigned: "Responsable asignado",
  sinister_responsible_unassigned: "Responsable removido",
  sinister_search: "B\xFAsqueda de siniestros",
  sinister_table_view: "Vista de tabla de siniestros",
  sinister_view: "Vista de siniestro",
  team_deleted: "Equipo eliminado",
  team_updated: "Equipo actualizado",
  upload_files: "Subida de archivos",
  claim_responsible_unassigned: "Responsable removido",
  claim_status_updated: "Estado de reclamo actualizado",
  claim_documentation_requested: "Documentacion solicitada",
  claim_documentation_completed: "Documentacion completa",
  contract_created: "Contrato creado",
  contract_report_created: "Reporte de contrato creado",
  contract_report_accepted: "Reporte de contrato aceptado",
  contract_report_rejected: "Reporte de contrato rechazado",
  offer_expired: "Ofrecimiento expirado",
  offer_rejected: "Ofrecimiento rechazado",
  offer_accepted: "Ofrecimiento aceptado",
  offer_canceled: "Ofrecimiento anulado",
  fraud_closed: "Fraude cerrado",
  resource_type: {
    claim: "reclamo",
    complaint: "denuncia"
  },
  complaint_form_step_view: "Vista de formulario de denuncia",
  rfp_bid_winner_selected: "Ganador de la oferta de licitaci\xF3n seleccionado",
  rfp_bid_submitted: "Oferta de licitaci\xF3n presentada",
  rfp_created: "Licitaci\xF3n creada",
  contract_updates_published: "Novedades de contrato publicadas",
  contract_updates_requested: "Novedades de contrato solicitadas",
  contract_invoice_uploaded: "Factura de contrato cargada",
  appraisal_invoice_uploaded: "Factura de peritaje cargada",
  appraisal_updates_published: "Novedades de peritaje publicadas",
  appraisal_updates_requested: "Novedades de peritaje solicitadas",
  appraisal_report_rejected: "Informe de evaluaci\xF3n rechazado",
  appraisal_report_created: "Informe de evaluaci\xF3n creado",
  appraisal_report_accepted: "Informe de evaluaci\xF3n aceptado",
  field_adjuster_assigned: "Liquidador/Perito asignado",
  fraud_updated: "Investigaci\xF3n de fraude actualizada",
  fraud_investigation_report_accepted: "Informe de investigaci\xF3n de fraude aceptado",
  complaint_file_request_uploaded: "Solicitud de documentaci\xF3n de denuncia cargada",
  file_request_uploaded: "Solicitud de documentaci\xF3n cargada",
  sinister_created: "Siniestro creado",
  sinister_third_party_complaints_view: "Vista de denuncias de terceros",
  complaint_created: "Denuncia creada",
  claim_document_view: "Vista de documento de reclamo",
  external_complaint_documentation_list_view: "Vista de documentaci\xF3n de denuncia externa",
  claim_documentation_list_view: "Vista de documentaci\xF3n de reclamo",
  fraud_activity_view: "Vista de actividad de fraude",
  complaint_activity_view: "Vista de actividad de denuncia",
  complaint_collaborator_unassigned: "Colaborador removido",
  claim_collaborator_unassigned: "Colaborador removido",
  complaint_status_updated: "Estado de denuncia actualizado",
  pending_sinister_complaint: "Denuncia de siniestro pendiente",
  pending_file_request_upload: "Solicitud de carga de documentaci\xF3n pendiente",
  criminal_case_request: "Solicitud de causa penal",
  claim_not_response: "Reclamo sin respuesta",
  claim_offer_flow: "Ofrecimiento de reclamo",
  claim_rejected: "Reclamo rechazado",
  claim_additional_management: "Gestiones adicionales de reclamo",
  claim_ownership_transfer_requested: "Solicitud de transferencia de propiedad de reclamo",
  claim_ownership_transfer_accepted: "Transferencia de propiedad de reclamo aceptada",
  claim_ownership_transfer_rejected: "Transferencia de propiedad de reclamo rechazada",
  claim_ownership_assigned: "Propiedad de reclamo asignada",
  claim_estimates_updated: "Estimaciones de reclamo actualizadas",
  pending_offer_response: "Respuesta de conformidad",
  pending_offer_agreement_response: "Respuesta acuerdo de conformidad pendiente",
  offer_agreement_completed: "Conformidad completada",
  offer_agreement_rejected: "Conformidad rechazada",
  offer_agreement_expired: "Conformidad expirada",
  offer_agreement_cancelled: "Conformidad anulada",
  pending_offer_response_internal: "Respuesta de oferta interna pendiente",
  pending_offer_agreement_response_internal: "Respuesta acuerdo de conformidad interna pendiente",
  claim_updated: "Reclamo actualizado",
  complaint_updated: "Denuncia actualizada",
  complaint_sinister_cause_updated: "Causa de siniestro de denuncia actualizada",
  external_complaint_selected: "Definir denuncia principal",
  claim_derived: "Reclamo derivado",
  claim_responsibility_returned: "Responsabilidad de reclamo devuelta",
  pending_complaint_created: "Denuncia de oficio creada",
  rfp_expired: "Licitaci\xF3n expirada",
  external_complaint_created: "Denuncia creada en el sistema tercero",
  complaint_authorized: "Denuncia autorizada",
  external_complaint_authorized: "Denuncia autorizada en el sistema tercero",
  complaint_approved: "Denuncia aprobada",
  external_complaint_approved: "Denuncia aprobada en el sistema tercero",
  complaint_rejected: "Denuncia rechazada",
  external_complaint_rejected: "Denuncia rechazada en el sistema tercero",
  complaint_estimates_updated: "Estimaciones de denuncia actualizadas",
  order_created: "Orden creada",
  order_report_created: "Reporte de orden creado",
  order_report_accepted: "Reporte de orden aceptado",
  order_report_rejected: "Reporte de orden rechazado",
  order_updates_published: "Novedades de orden publicadas",
  order_updates_requested: "Novedades de orden solicitadas",
  order_invoice_uploaded: "Factura de orden cargada",
  alert_sla_objective_progress: "Alerta de progreso de objetivo de SLA",
  alert_sla_total_progress: "Alerta de progreso total de SLA",
  sinister_external_status_updated: "Estado externo de siniestro actualizado",
  complaint_confirmation_signature: "Denuncia firmada",
  tags_view: "Vista de etiquetas",
  tag_created: "Etiqueta creada",
  tag_updated: "Etiqueta actualizada",
  tag_deleted: "Etiqueta eliminada",
  claim_file_uploaded: "Documento de reclamo cargado",
  claim_file_uploaded_by_insurer: "Documento de reclamo cargado"
};
var entities = {
  vehicle_id_card_back: {
    label: "C\xE9dula (dorso)",
    owner_name: "Nombre",
    owner_address: "Direcci\xF3n",
    owner_identity_number: "Documento"
  },
  vehicle_id_card_front: {
    label: "C\xE9dula (frente)",
    license_plate: "Patente",
    id_card_number: "Documento",
    chassis_number: "Chasis",
    model: "Modelo",
    make: "Marca",
    type: "Tipo",
    use: "Uso",
    motor: "Motor",
    expiration_date: "Fecha de expiraci\xF3n"
  },
  death_certificate: {
    label: "Certificado de defunci\xF3n",
    day: "Dia",
    hour: "Hora",
    year: "A\xF1o",
    reason: "Motivo"
  },
  medical_certificate: {
    label: "Certificado m\xE9dico",
    doctor_full_name: "Medico",
    doctor_license_number: "Matricula",
    date: "Fecha",
    diagnosis: "Diagn\xF3stico"
  },
  coverage_certificate: {
    label: "Certificado de cobertura",
    effective_date_from: "Vigencia desde",
    effective_date_to: "Vigencia hasta"
  },
  vehicle: {
    label: "Veh\xEDculo",
    license_plate: "Patente"
  },
  national_identity_card: {
    label: "DNI",
    first_name: "Nombre",
    last_name: "Apellido",
    birth_date: "Fecha de nacimiento",
    issued_date: "Fecha de emision",
    sex: "Sexo",
    expiration_date: "Fecha de expiraci\xF3n",
    document_number: "Documento"
  }
};
var validations = {
  document_number: "Numero de documento",
  full_name: "Nombre",
  address: "Direcci\xF3n/Domicilio",
  make: "Marca",
  model: "Modelo",
  license_plate: "Patente/Dominio",
  birth_date: "Fecha de nacimiento",
  victim_national_identity_card_birth_date: "Fecha de nacimiento",
  medical_certificate_sinister_date: "Dias h\xE1biles",
  health_insurance_fund: "Obra social",
  doctor_stamp_visible: "Sello del m\xE9dico",
  doctor_license_number_visible: "Matr\xEDcula del m\xE9dico",
  diagnosis_visible: "Diagn\xF3stico",
  vehicle_license_plate: "Patente",
  vehicle_ownership: "Propiedad del veh\xEDculo",
  policy_period: "Vigencia de la p\xF3liza",
  death_certificate_fields: "Certificado de defunci\xF3n",
  national_identity_card_fields: "DNI",
  worker_compensation_insurer: "ART"
};
var entrypoints = {
  claim: "Reclamo",
  complaint: "Denuncia"
};
var backends = {
  rivadavia: "Seguros Rivadavia",
  tajy: "Tajy Seguros"
};
var file_types = {
  other: "Otro",
  pdf: "PDF",
  audio: "Audio",
  image: "Imagen",
  document: "Documento",
  video: "Video",
  any: "Cualquier archivo"
};
var sinister = {
  tabs: {
    documents: "Documentaci\xF3n",
    claims: "Reclamos",
    activity: "Actividad"
  },
  status: {
    pending: "Pendiente",
    open: "Abierto",
    closed: "Cerrado",
    rejected: "Rechazado",
    in_process: "En proceso"
  }
};
var errors = {
  team_name_already_exists: {
    title: "Equipo ya existente",
    description: "Ya existe un equipo con el nombre '{{name}}'"
  },
  invalid_claim_system_status: {
    title: "Reclamo cerrado",
    description: "No puedes realizar modificaciones sobre un reclamo cerrado"
  },
  complaint_is_already_closed: {
    title: "Denuncia cerrada",
    description: "La denuncia se encuentra cerrada, por lo que no puedes cambiar al estado deseado"
  },
  status_already_linked: {
    title: "Equivalencia vinculada",
    description: "Este estado se encuentra dentro de una equivalencia, por lo que no puedes eliminarlo"
  },
  existent_trigger_mapping: {
    title: "Opci\xF3n vinculada en equivalencias",
    description: "Esta opci\xF3n se encuentra vinculada a una equivalencia con el estado: {{triggerMappingStatusName}}. Aseg\xFArate de eliminar la equivalencia antes de eliminar la opci\xF3n"
  },
  invalid_role: {
    title: "Permisos insuficientes",
    description: "Tu rol no cuenta con los permisos necesarios para realizar esta acci\xF3n"
  },
  zip_code_not_found: {
    title: "C\xF3digo postal invalido",
    description: "El c\xF3digo postal no es valido seg\xFAn el pa\xEDs y provincia ingresado"
  },
  overlapping_sla: {
    title: "Conflicto con otra version",
    description: "Ya existe otra version de SLA con la misma fecha de vigencia"
  },
  integration_auth_error: {
    title: "Error de autenticaci\xF3n de integraci\xF3n",
    description: "Se ha producido un error de autenticaci\xF3n de integraci\xF3n. Aseg\xFArate de tener una conexi\xF3n v\xE1lida y que tu usuario tenga permisos para acceder al recurso solicitado."
  },
  not_found_provider_template: {
    title: "Plantilla no encontrada",
    description: "No se pudo encontrar la plantilla seleccionada en el proveedor. Aseg\xFArate de tener una conexi\xF3n valida y que tu usuario tenga permisos para acceder a la plantilla."
  },
  unexpected_provider_error: {
    title: "Error inesperado en proveedor",
    description: "Ocurri\xF3 un error inesperado al momento de comunicarse con el proveedor. Intenta de nuevo o mas tarde."
  },
  missing_variable_definition: {
    title: "Referencia a variable invalida",
    description: "El mensaje contiene una referencia una variable inexistente"
  },
  wrong_ownership_transfer_destination_user: {
    title: "Usuario invalido",
    description: "Esta solicitud de transferencia de propiedad del reclamo le pertenece al usuario {{destination_user_email}}"
  },
  not_found_destination_user: {
    title: "Usuario no encontrado",
    description: "El correo ingresado no pertenece a ning\xFAn usuario. Aseg\xFArate de que el correo sea correcto"
  },
  wrong_recoverable_user_company: {
    title: "Empresa invalida",
    description: "No puedes registrarte con este correo ya que pertenece a un usuario de otra empresa"
  },
  unrecoverable_user_type: {
    title: "Tipo de usuario invalido",
    description: "No puedes registrarte con este correo ya que posee un rol no apto para la plataforma actual"
  },
  cannot_transfer_to_yourself: {
    title: "Usuario invalido",
    description: "No puedes transferir un reclamo a ti mismo"
  },
  invalid_field_value_from_provider: {
    title: "Error de validaci\xF3n de campo",
    description: "El proveedor ha indicado que el valor del campo '{{field_label}}' no es v\xE1lido de acuerdo a la configuraci\xF3n de la plantilla en la plataforma externa"
  },
  user_already_exists: {
    title: "Usuario ya existente",
    description: "Este usuario ya existe actualmente. Aseg\xFArate de ingresar un correo de un usuario que no forme parte de la plataforma"
  },
  complaint_already_exists: {
    title: "Denuncia duplicada",
    description: "Recientemente se ha creado una denuncia id\xE9ntica a la actual"
  },
  license_plate_remote_not_found: {
    title: "Patente invalida",
    description: "La patente ingresada no existe como patente de un asegurado"
  },
  role_name_already_exists: {
    title: "Nombre de rol existente",
    description: "El nombre de rol ya existe. Por favor, ingrese otro nombre."
  },
  company_not_found: {
    title: "Empresa no encontrada",
    description: "La empresa ingresada no fue encontrada."
  },
  role_not_found: {
    title: "Rol no encontrado",
    description: "El rol ingresado no fue encontrado."
  },
  invalid_user_type: {
    title: "Tipo de usuario inv\xE1lido",
    description: "El tipo de usuario ingresado no es v\xE1lido."
  },
  not_found_user: {
    title: "Usuario no encontrado",
    description: "El usuario ingresado no fue encontrado."
  },
  emails_already_exists: {
    title: "Emails existentes",
    description: "Los emails ingresados ya existen en nuestra base de datos. Por favor, ingrese otros emails."
  },
  invitations_already_exists: {
    title: "Invitaciones existentes",
    description: "Las invitaciones ingresadas ya existen en nuestra base de datos. Por favor, ingrese otras invitaciones."
  },
  invitation_not_found: {
    title: "Invitaci\xF3n no encontrada",
    description: "La invitaci\xF3n ingresada no fue encontrada."
  },
  username_already_exists: {
    title: "Nombre de usuario existente",
    description: "El nombre de usuario ya existe en nuestra base de datos. Por favor, ingrese otro nombre de usuario."
  },
  not_found_claim: {
    title: "Reclamo no encontrado",
    description: "El reclamo ingresado no fue encontrado."
  },
  wrong_identity_email: {
    title: "Email incorrecto",
    description: "El email ingresado no se pudo autenticar. Por favor, ingrese otro email."
  },
  identity_already_exists: {
    title: "Identity provider existente",
    description: "El IDP ya existe en nuestra base de datos. Por favor, ingrese otra identidad."
  },
  style_already_exists: {
    title: "Estilo existente",
    description: "El estilo ya existe en nuestra base de datos. Por favor, ingrese otro estilo."
  },
  style_not_found: {
    title: "Estilo no encontrado",
    description: "El estilo ingresado no fue encontrado."
  },
  not_found_entrypoint: {
    title: "Entrypoint no encontrado",
    description: "El entrypoint ingresado no fue encontrado."
  },
  not_found_ramo: {
    title: "Ramo no encontrado",
    description: "El ramo ingresado no fue encontrado."
  },
  axios_bad_request: {
    title: "Error de solicitud",
    description: "Error de solicitud"
  },
  cookie_not_found: {
    title: "Cookie no encontrada",
    description: "La cookie ingresada no fue encontrada."
  },
  not_found_fraud: {
    title: "Fraude no encontrado",
    description: "El fraude ingresado no fue encontrado."
  },
  not_found_default_status: {
    title: "Estado por defecto no encontrado",
    description: "El estado por defecto ingresado no fue encontrado."
  },
  not_found_company_status: {
    title: "Estado no encontrado",
    description: "El estado de empresa ingresado no fue encontrado."
  },
  claim_is_already_closed: {
    title: "Reclamo cerrado",
    description: "El reclamo ingresado ya se encuentra cerrado."
  },
  not_found_adobe_sign_connection: {
    title: "Conexi\xF3n de Adobe Sign no encontrada",
    description: "La conexi\xF3n de Adobe Sign ingresada no fue encontrada."
  },
  not_found_job: {
    title: "Tarea no encontrado",
    description: "La tarear ingresado no fue encontrado."
  },
  company_link_not_found: {
    title: "Link de empresa no encontrado",
    description: "El link de empresa ingresado no fue encontrado."
  },
  not_found_coordinator: {
    title: "Coordinador no encontrado",
    description: "El coordinador ingresado no fue encontrado."
  },
  invalid_style_scope: {
    title: "Scope inv\xE1lido",
    description: "El scope ingresado no es v\xE1lido."
  },
  invalid_file_token: {
    title: "Token de archivo inv\xE1lido",
    description: "El token de archivo ingresado no es v\xE1lido"
  },
  not_found_permissions: {
    title: "Permisos no encontrados",
    description: "Los permisos ingresados no fueron encontrados"
  },
  user_is_responsible: {
    title: "Usuario es responsable",
    description: "El usuario seleccionado es responsable de uno o mas siniestros. Por favor, reasigne los siniestros antes de eliminar el usuario"
  },
  unauthorized: {
    title: "No autorizado",
    description: "El token enviado no es valido"
  },
  empty_team: {
    title: "Equipo vac\xEDo",
    description: "El grupo de destino seleccionado no cuenta con usuarios que puedan ser asignados al reclamo"
  },
  case_request_repeated: {
    title: "Derivaci\xF3n ya realizada",
    description: "Ya hay una derivaci\xF3n a fraudes con el numero de siniestro ingresado"
  },
  not_found_sinisterId: {
    title: "Siniestro no encontrado",
    description: "El siniestro ingresado no fue encontrado en la integraci\xF3n"
  },
  integration_invalid_credentials: {
    title: "Credenciales inv\xE1lidas",
    description: "Las credenciales configuradas en la integraci\xF3n no son validas"
  },
  not_found_sinister: {
    title: "Siniestro no encontrado",
    description: "El siniestro ingresado no fue encontrado en la integraci\xF3n"
  },
  not_found_policy: {
    title: "P\xF3liza no encontrada",
    description: "La p\xF3liza ingresada no fue encontrada en la integraci\xF3n"
  },
  not_found_matricula: {
    title: "Matricula no encontrada",
    description: "La matricula ingresada no fue encontrada en la integraci\xF3n"
  },
  email_mismatch: {
    title: "Cuenta incorrecta",
    description: "El correo electr\xF3nico de la cuenta seleccionada no coincide con el correo electr\xF3nico de la cuenta actual. Por favor, seleccione una cuenta diferente."
  },
  domain_not_allowed: {
    title: "Dominio no permitido",
    description: "El dominio de la direcci\xF3n de correo electr\xF3nico no pertenece a la empresa"
  },
  endpoint_already_exists: {
    title: "Endpoint ya existente",
    description: "El endpoint ya existe en nuestra base de datos. Por favor, ingrese otro endpoint."
  },
  user_already_assigned: {
    title: "Usuario ya asignado",
    description: "El usuario ya se encuentra asignado al reclamo"
  },
  user_not_verified: {
    title: "Cuenta no verificada",
    description: "Por favor, verifique su cuenta para poder continuar."
  },
  cannot_process_request: {
    title: "No se pudo procesar la solicitud",
    description: "Por favor, intente nuevamente m\xE1s tarde."
  },
  wrong_target_user: {
    title: "Usuario invalido",
    description: "Este usuario no puede acceder a esta plataforma"
  },
  invalid_credentials: {
    title: "Credenciales incorrectas",
    description: "El email o la contrase\xF1a son incorrectos."
  },
  email_already_exists: {
    title: "Email existente",
    description: "El correo ingresado ya se encuentra en uso. Por favor, reg\xEDstrate con otro email o ingresa a la plataforma en caso de ya estar registrado."
  },
  company_name_already_exists: {
    title: "Nombre de empresa en uso",
    description: "El nombre de la empresa ya se encuentra en uso. Por favor, utilice uno diferente"
  },
  company_ein_already_exists: {
    title: "CUIT/CUIL de empresa en uso",
    description: "El CUIT/CUIL de la empresa ya se encuentra en uso. Por favor, utilice uno diferente"
  },
  company_domain_already_exists: {
    title: "Dominio de empresa en uso",
    description: "El dominio de la empresa ya se encuentra en uso. Por favor, utilice uno diferente"
  },
  user_dni_already_exists: {
    title: "Dni invalido",
    description: "Ya existe un usuario con el DNI ingresado. Por favor, utilice uno diferente"
  },
  user_email_already_exists: {
    title: "Email invalido",
    description: "Ya existe un usuario con el email ingresado. Por favor, utilice uno diferente"
  },
  user_ein_already_exists: {
    title: "CUIT/CUIL invalido",
    description: "Ya existe un usuario con el CUIT/CUIL ingresado. Por favor, utilice uno diferente"
  },
  unique_company_currency: {
    title: "No se puede eliminar",
    description: "La moneda es la \xFAnica disponible"
  },
  duplicate_file_request_placeholders: {
    title: "Documentos repetidos",
    description: "Los siguientes documentos ya fueron solicitados anteriormente: {{duplicate_tags}}"
  },
  tag_already_exists: {
    title: "Etiqueta ya existente",
    description: "Ya existe una etiqueta con este nombre"
  }
};
var legalPersonType = {
  hospital: "Hospital",
  worker_compensation_insurer: "ART",
  health_insurance_fund: "Obra social",
  insurer: "Aseguradora"
};
var claim_properties_insurance_insurer = "Aseguradora";
var claim_properties_insurance_policy_number = "N\xFAmero de p\xF3liza";
var claim_properties_driver_ownership_owner = "Due\xF1o";
var claim_properties_vehicle_type_car = "Auto";
var claim_properties_vehicle_category_with_license_plate = "Veh\xEDculo con patente";
var claim_properties_driver_owner = "Due\xF1o";
var claim_properties_vehicle_document_type_title = "Titulo";
var claim_properties_insurance_coverage_third_full = "Tercero Completo";
var claim_victim_relationship_own = "Directo";
var claim_properties_damage_driver_last_name = "Apellido";
var claim_properties_damage_driver_first_name = "Nombre";
var claim_properties_damage_hasInsurance = "\xBFTiene seguro?";
var claim_properties_damage_type = "Tipo de da\xF1o";
var claim_properties_vehicle_category = "Categor\xEDa";
var claim_properties_vehicle_type = "Tipo de veh\xEDculo";
var claim_properties_vehicle_year = "Patente";
var claim_properties_vehicle_license_plate = "Patente";
var claim_properties_vehicle_engine = "Motor";
var claim_properties_vehicle_chassis = "Chasis";
var claim_properties_vehicle_use = "Uso";
var claim_properties_vehicle_model = "Modelo";
var claim_properties_vehicle_make = "Marca";
var claim_properties_vehicle_version = "Version";
var claim_properties_vehicle_document_type = "Documentaci\xF3n";
var claim_properties_driver_ownership = "Propiedad";
var claim_properties_insurance_coverage = "Cobertura";
var damages_material_vehicle = "Veh\xEDculo";
var damages_material_real_property = "Inmueble";
var materialDamage = {
  vehicle: "Veh\xEDculo",
  real_property: "Inmueble"
};
var parents = {
  mother: "Madre",
  father: "Padre"
};
var claim_identification_type_label = "Tipo de documento";
var identification_type = {
  national_id_document: "DNI",
  enrollment_book: "Libreta Enrolamiento",
  civil_registry_card: "Libreta C\xEDvica",
  passport: "Pasaporte",
  cuil: "CUIL",
  cuit: "CUIT"
};
var local_insured_relationships = {
  friend: "Amigo/a",
  brother: "Hermano/a",
  couple: "Pareja",
  parent: "Padre/Madre",
  son: "Hijo/a",
  uncle: "T\xEDo/a",
  cousin: "Primo/a",
  niece: "Sobrino/a",
  rider: "Pasajero - uso comercial",
  other: "Otro",
  spouse: "Conyuge",
  concubine: "Concubina"
};
var victim_injuries_roles = {
  pedestrian: "Peat\xF3n",
  driver: "Conductor del veh\xEDculo tercero",
  rider: "Transportado en el veh\xEDculo tercero",
  policyholder_rider: "Transportado en el veh\xEDculo asegurado en {{insurerName}}"
};
var coverages = {
  civil_responsibility: "Responsabilidad Civil",
  third_robbery_fire: "Tercero, Robo e incendio",
  third_full: "Tercero Completo",
  all_risks_without_franchise: "Todo Riesgo sin Franquicia",
  all_risks_with_franchise: "Todo Riesgo con Franquicia"
};
var driver_ownership = {
  owner: "Due\xF1o",
  third: "Tercero",
  own: "Due\xF1o"
};
var real_property = {
  ownership: {
    own: "Propietario",
    rented: "Inquilino",
    other: "Otro"
  },
  type: {
    housing: "Vivienda",
    commercial: "Local Comercial",
    building: "Edificio",
    government: "Organismo Publico",
    warehouse: "Galp\xF3n",
    other: "Otro"
  }
};
var vehicle = {
  identification_key: {
    license_plate: "Numero de chapa",
    chassis: "N\xFAmero de chasis"
  },
  bike: "Bicicleta",
  machinery: "Maquinaria",
  skateboard: "Monopat\xEDn",
  other: "Otro",
  car: "Auto",
  moto: "Motocicleta-Motoneta-Cuatriciclo",
  documents: {
    title: "Titulo",
    registration: "C\xE9dula Verde",
    other: "Otro"
  },
  category: {
    with_license_plate: "Veh\xEDculo con patente (Auto o Moto)",
    without_license_plate: "Veh\xEDculo sin patente (Bicicleta, Monopatines, Maquinaria, etc)"
  },
  use: {
    special_school_pickup: "Servicios Especiales y Escolares (Pick Up)",
    private: "Particular",
    public: "P\xFAblico",
    taxi: "Taxi",
    remis: "Remis",
    rental: "Alquiler de Autos",
    ambulance: "Ambulancia",
    police: "Patrullero",
    school: "Escuela",
    specialService: "Sev. Especial Plataforma Electron",
    special_service: "Serv. Especiales (Excepto Pick Up)",
    goods: "Transporte de mercader\xEDas",
    persons: "Transporte de personas",
    goods_transport: "Transporte de Bienes",
    local: "Locales",
    route_service: "Ruteros",
    school_transport: "Escolares",
    electronic_platform: "Servicio Especial Plataforma Electr\xF3nica",
    delivery_service: "Servicio de Reparto y Mensajer\xEDa",
    agency: "Agencias (Con/Sin Chofer c/ chapa Particular)"
  },
  special_transport_service: "Servicio Transporte Especial",
  public_transport_service: "Servicio Transporte P\xFAblico",
  trailer_motor_home: "Trailer - Casa Rodante",
  pickup_class_a_and_b_jeep: "Pick Up Clase 'A' y 'B' - Jeep",
  truck_chassis: "Cami\xF3n - Chasis",
  hitched_semi_trailer: "Acoplado - Semiremolque",
  tractors_and_rural_machines: "Tractores y M\xE1quinas Rurales"
};
var type = {};
var claim_state_label = "Provincia";
var claim_city_label = "Ciudad";
var claim_country_label = "Pa\xEDs";
var claim_identification_label = "Identificaci\xF3n";
var claim_first_name_label = "Nombre";
var claim_last_name_label = "Apellido";
var claim_cuit_label = "CUIT";
var claim_address_label = "Direcci\xF3n";
var claim_type_label = "Tipo";
var claim_social_reason_label = "Raz\xF3n Social";
var claim_zip_code_label = "C\xF3digo Postal";
var claim_victim_relationship_label = "Relaci\xF3n con el asegurado";
var fractures = {
  upper: "Miembro superior",
  lower: "Miembro inferior",
  right: "Miembro derecho",
  left: "Miembro izquierdo"
};
var severities = {
  major: "Mayor",
  minor: "Menor",
  moderate: "Moderado"
};
var injuries = {
  abrasions: "Escoriaciones",
  whiplash: "Traumatismos varios / Latigazo",
  severe_had_injury: "Traumatismo de cr\xE1neo grave",
  fractures: "Fracturas"
};
var victim_relationship = {
  label: "Relaci\xF3n con el asegurado",
  mother: "Madre",
  father: "Padre",
  other_family_member: "Otro familiar",
  tutor_curator: "Tutor/Curador",
  attorney_in_fact: "Abogado Apoderado",
  legal_representative: "Apoderado legal",
  attorney_representative: "Abogado patrocinante",
  other: "Otro",
  brother: "Hermano",
  producer: "Productor",
  workshopper: "Tallerista",
  own: "Reclamo en nombre propio",
  literate: "Letrado",
  particular_victim: "Damnificado particular"
};
var sex = {
  male: "Masculino",
  female: "Femenino",
  other: "Otro"
};
var damages = {
  with_license_plate: "Con patente",
  without_license_plate: "Sin patente",
  real_property: "Inmueble",
  vehicle: "Veh\xEDculo",
  person: "Da\xF1os a Personas",
  death: "Fallecido",
  injury: "Lesiones",
  recovery: "Recuperos",
  material: "Da\xF1os materiales",
  injuries: "Lesiones",
  deceased: "Fallecido",
  hospital: "Hospitales",
  healthInsuranceFund: "Obra social",
  workerCompensationInsurer: "ART",
  insurer: "Aseguradoras"
};
var company_service = {
  backends: {
    rivadavia: "Seguros Rivadavia"
  },
  claim: "Reclamo",
  complaint: "Denuncia"
};
var permissions = {
  can_create_claim: "Crear Reclamo",
  can_read_claim: "Visualizar Reclamo",
  can_update_claim: "Actualizar Reclamo",
  can_delete_claim: "Eliminar Reclamo",
  can_update_sinister: "Actualizar Siniestro",
  can_read_sinister: "Visualizar Siniestro",
  can_create_sinister: "Crear Siniestro",
  can_delete_sinister: "Eliminar Siniestro",
  can_read_team: "Visualizar Grupo Destino",
  can_create_team: "Crear Grupo Destino",
  can_delete_team: "Eliminar Grupo Destino",
  can_update_team: "Actualizar Grupo Destino",
  can_create_load_balance_rule: "Crear Regla de Distribuci\xF3n",
  can_update_load_balance_rule: "Actualizar Regla de Distribuci\xF3n",
  can_read_load_balance_rule: "Visualizar Regla de Distribuci\xF3n",
  can_delete_load_balance_rule: "Eliminar Regla de Distribuci\xF3n",
  can_read_role: "Visualizar Rol",
  can_create_role: "Crear Rol",
  can_update_role: "Actualizar Rol",
  can_delete_role: "Eliminar Rol",
  can_read_user: "Visualizar Usuario",
  can_create_user: "Crear Usuario",
  can_update_user: "Actualizar Usuario",
  can_delete_user: "Eliminar Usuario",
  can_read_complaint: "Visualizar Denuncia",
  can_create_complaint: "Crear Denuncia",
  can_update_complaint: "Actualizar Denuncia",
  can_delete_complaint: "Eliminar Denuncia",
  can_read_invitation: "Visualizar Invitaci\xF3n",
  can_create_invitation: "Crear Invitaci\xF3n",
  can_update_invitation: "Actualizar Invitaci\xF3n",
  can_delete_invitation: "Eliminar Invitaci\xF3n",
  can_read_settings: "Visualizar Configuraci\xF3n",
  can_update_settings: "Actualizar Configuraci\xF3n",
  can_read_account: "Visualizar Cuenta",
  can_update_account: "Actualizar Cuenta",
  can_read_notification: "Visualizar Notificaciones"
};
var company_enums_claim_status = "Estados del reclamo";
var company_enums_claim_stage = "Etapas del reclamo";
var company_enums_claim_reference = "Referencias del reclamo";
var company_enums_complaint_status = "Estados de la denuncia";
var company_enums_complaint_stage = "Etapas de la denuncia";
var company_enums_complaint_reference = "Referencias de la denuncia";
var company_enums_ramos = "Ramo";
var company_enums_agencies = "Agencia";
var company_enums_complaint_public_status = "Estado publico de la denuncia";
var company_enums_claim_public_status = "Estado publico de reclamo";
var _claims = "Reclamos";
var _complaints = "Denuncias";
var _companies = "Compa\xF1ias";
var _users = "Usuarios";
var _integrations = "Integraciones";
var _recordings = "Grabaciones";
var style_config = {
  scope: {
    insurer: "Aseguradora",
    claim: "Reclamo",
    complaint: "Denuncia"
  },
  "font-family": {
    inter: "Inter",
    roboto: "Roboto",
    roboto_mono: "Roboto mono",
    space_grotesk: "Space grotesk",
    space_mono: "Space mono",
    noto_color_emoji: "Noto Color Emoji",
    playfair_display: "Playfair Display",
    prata: "Prata",
    glegoo: "Glegoo",
    robot_flex: "Roboto flex",
    roboto_condensed: "Roboto Condensed",
    roboto_slab: "Roboto Slab",
    ibm_plex_sans_condensed: "IBM Plex Sans Condensed",
    ubuntu_condensed: "Ubuntu Condensed",
    barlow_condensed: "Barlow Condensed",
    saira_condensed: "Saira Condensed"
  }
};
var fraud_investigation_case = {
  fields: {
    case_type: {
      insured: "Asegurado",
      third_party: "Tercero"
    },
    insurer: "Aseguradora",
    sinister_type: "Tipo de siniestro",
    reason: "Motivo del an\xE1lisis",
    policy_id: "N\xFAmero de p\xF3liza",
    instance: "Instancia",
    matricula: "Matricula",
    description: "Indicadores o motivos espec\xEDficos de la elevaci\xF3n",
    denounced_at: "Fecha de denuncia",
    annotation: "Informaci\xF3n extra"
  }
};
var role = {
  permissions: {
    labels: {
      manage_case_load_balance_rule: "Gestionar Reglas de distribuci\xF3n",
      manage_team: "Gestionar Grupos destino",
      manage_sinister: "Gestionar Siniestro",
      read_sinister: "Visualizar Siniestro",
      manage_complaint: "Gestionar Denuncia",
      read_complaint: "Visualizar Denuncia",
      manage_claim: "Gestionar Reclamo",
      read_claim: "Visualizar Reclamo",
      manage_role: "Gestionar Roles",
      manage_user: "Gestionar Usuarios",
      manage_settings: "Gestionar Settings",
      read_notification: "Visualizar Notificaciones",
      read_company_link: "Visualizar Accesos Directos"
    }
  }
};
var webhook = {
  events: {
    "claim#created": {
      title: "Reclamo creado"
    },
    "claim#sla#objective#instance#threshold#exceeded": {
      title: "Umbral de objetivo SLA excedido"
    },
    "claim#sla#instance#threshold#exceeded": {
      title: "Umbral de instancia SLA excedido"
    },
    "claim#ownership_transfer#created": {
      title: "Transferencia de propiedad creada"
    },
    "claim#ownership_transfer#accepted": {
      title: "Transferencia de propiedad aceptada"
    },
    "claim#ownership_transfer#rejected": {
      title: "Transferencia de propiedad rechazada"
    },
    "claim#file_request#created": {
      title: "Solicitud de archivo creada"
    },
    "claim#updated": {
      title: "Reclamo actualizado"
    },
    "claim#message#created": {
      title: "Mensaje creado"
    },
    "claim#file#uploaded": {
      title: "Archivo subido"
    },
    "claim#handler#assigned": {
      title: "Tramitador asignado"
    },
    "claim#handler#unassigned": {
      title: "Tramitador desasignado"
    },
    "claim#status#updated": {
      title: "Estado actualizado"
    },
    "claim#order#created": {
      title: "Orden creada"
    },
    "claim#order#invoice#created": {
      title: "Factura de orden creada"
    },
    "claim#order#updates#requested": {
      title: "Actualizaciones de orden solicitadas"
    },
    "claim#order#updates#published": {
      title: "Actualizaciones de orden publicadas"
    },
    "claim#order#report#created": {
      title: "Informe de orden creado"
    },
    "claim#order#report#accepted": {
      title: "Informe de orden aceptado"
    },
    "claim#order#report#rejected": {
      title: "Informe de orden rechazado"
    },
    "claim#offer#created": {
      title: "Oferta creada"
    },
    "claim#offer#accepted": {
      title: "Oferta aceptada"
    },
    "claim#offer#rejected": {
      title: "Oferta rechazada"
    },
    "claim#offer#expired": {
      title: "Oferta expirada"
    },
    "claim#offer#canceled": {
      title: "Oferta anulada"
    },
    "claim#offer#agreement#created": {
      title: "Acuerdo de oferta creado"
    },
    "claim#offer#agreement#completed": {
      title: "Acuerdo de oferta completado"
    },
    "claim#offer#agreement#rejected": {
      title: "Acuerdo de oferta rechazado"
    },
    "claim#offer#agreement#expired": {
      title: "Acuerdo de oferta expirado"
    },
    "claim#offer#agreement#canceled": {
      title: "Acuerdo de oferta cancelado"
    },
    "claim#rfp#created": {
      title: "Solicitud de propuesta creada"
    },
    "claim#rfp#closed": {
      title: "Solicitud de propuesta cerrada"
    },
    "claim#rfp#canceled": {
      title: "Solicitud de propuesta cancelada"
    },
    "claim#rfp#bid#submitted": {
      title: "Oferta de RFP enviada"
    },
    "claim#rfp#bid#selected": {
      title: "Oferta de RFP seleccionada"
    },
    "claim#estimates#updated": {
      title: "Estimaciones actualizadas"
    },
    "claim#appraisal#field_adjuster#assigned": {
      title: "Perito de campo asignado"
    },
    "claim#appraisal#invoice#uploaded": {
      title: "Factura de peritaje subida"
    },
    "claim#appraisal#report#created": {
      title: "Informe de peritaje creado"
    },
    "claim#appraisal#report#accepted": {
      title: "Informe de peritaje aceptado"
    },
    "claim#appraisal#report#rejected": {
      title: "Informe de peritaje rechazado"
    },
    "claim#appraisal#updates#requested": {
      title: "Actualizaciones de peritaje solicitadas"
    },
    "claim#appraisal#updates#published": {
      title: "Actualizaciones de peritaje publicadas"
    }
  },
  scope: {
    user: "Usuario",
    company: "Compa\xF1ia"
  }
};
var resources = {
  claims: "Reclamo",
  complaints: "Denuncia"
};
var occurrence = {
  circulation_direction: {
    unique: "Unica",
    both: "Ambas"
  }
};
var currencies = {
  labels: {
    PYG: "Guaran\xED",
    USD: "D\xF3lar",
    EUR: "Euro",
    ARS: "Peso Argentino"
  }
};
var company = {
  properties: {
    name: "Nombre",
    domain: "Dominio",
    type: "Tipo",
    country: "Pa\xEDs",
    ein: "CUIT/CUIL",
    alias: "Alias"
  }
};
var es_default = {
  field_mapping_config,
  message_template,
  stats,
  chart,
  distribution,
  "trigger-mappings": trigger_mappings,
  sla,
  "working-days": working_days,
  "payment-method": payment_method,
  "estimates-definition": estimates_definition,
  person,
  entrypoint,
  "target-group": target_group,
  actions,
  operators,
  delta,
  offer,
  orders,
  variable,
  "inhouse-lawyer": inhouse_lawyer,
  load_balance_rule,
  document,
  circulation,
  license,
  contract,
  cost,
  bid,
  rfp,
  contact,
  filters,
  legal_person,
  user,
  zod,
  inspection,
  professions,
  "exported-report": exported_report,
  claims,
  notifications,
  fraud,
  breadcrumb,
  complaint,
  "validation-pattern": validation_pattern,
  frequency,
  claimants,
  claim,
  events,
  entities,
  validations,
  entrypoints,
  backends,
  file_types,
  sinister,
  errors,
  legalPersonType,
  "claim.properties.insurance.insurer": claim_properties_insurance_insurer,
  "claim.properties.insurance.policy_number": claim_properties_insurance_policy_number,
  "claim.properties.driver.ownership.owner": claim_properties_driver_ownership_owner,
  "claim.properties.vehicle.type.car": claim_properties_vehicle_type_car,
  "claim.properties.vehicle.category.with_license_plate": claim_properties_vehicle_category_with_license_plate,
  "claim.properties.driver.owner": claim_properties_driver_owner,
  "claim.properties.vehicle.document_type.title": claim_properties_vehicle_document_type_title,
  "claim.properties.insurance.coverage.third_full": claim_properties_insurance_coverage_third_full,
  "claim.victim_relationship.own": claim_victim_relationship_own,
  "claim.properties.damage.driver.last_name": claim_properties_damage_driver_last_name,
  "claim.properties.damage.driver.first_name": claim_properties_damage_driver_first_name,
  "claim.properties.damage.hasInsurance": claim_properties_damage_hasInsurance,
  "claim.properties.damage.type": claim_properties_damage_type,
  "claim.properties.vehicle.category": claim_properties_vehicle_category,
  "claim.properties.vehicle.type": claim_properties_vehicle_type,
  "claim.properties.vehicle.year": claim_properties_vehicle_year,
  "claim.properties.vehicle.license_plate": claim_properties_vehicle_license_plate,
  "claim.properties.vehicle.engine": claim_properties_vehicle_engine,
  "claim.properties.vehicle.chassis": claim_properties_vehicle_chassis,
  "claim.properties.vehicle.use": claim_properties_vehicle_use,
  "claim.properties.vehicle.model": claim_properties_vehicle_model,
  "claim.properties.vehicle.make": claim_properties_vehicle_make,
  "claim.properties.vehicle.version": claim_properties_vehicle_version,
  "claim.properties.vehicle.document_type": claim_properties_vehicle_document_type,
  "claim.properties.driver.ownership": claim_properties_driver_ownership,
  "claim.properties.insurance.coverage": claim_properties_insurance_coverage,
  "damages.material.vehicle": damages_material_vehicle,
  "damages.material.real_property": damages_material_real_property,
  materialDamage,
  parents,
  "claim.identification_type.label": claim_identification_type_label,
  "identification-type": identification_type,
  "local-insured-relationships": local_insured_relationships,
  "victim-injuries-roles": victim_injuries_roles,
  coverages,
  "driver-ownership": driver_ownership,
  real_property,
  vehicle,
  type,
  "claim.state.label": claim_state_label,
  "claim.city.label": claim_city_label,
  "claim.country.label": claim_country_label,
  "claim.identification.label": claim_identification_label,
  "claim.first_name.label": claim_first_name_label,
  "claim.last_name.label": claim_last_name_label,
  "claim.cuit.label": claim_cuit_label,
  "claim.address.label": claim_address_label,
  "claim.type.label": claim_type_label,
  "claim.social_reason.label": claim_social_reason_label,
  "claim.zip_code.label": claim_zip_code_label,
  "claim.victim_relationship.label": claim_victim_relationship_label,
  fractures,
  severities,
  injuries,
  victim_relationship,
  sex,
  damages,
  "company-service": company_service,
  permissions,
  "company-enums.claim_status": company_enums_claim_status,
  "company-enums.claim_stage": company_enums_claim_stage,
  "company-enums.claim_reference": company_enums_claim_reference,
  "company-enums.complaint_status": company_enums_complaint_status,
  "company-enums.complaint_stage": company_enums_complaint_stage,
  "company-enums.complaint_reference": company_enums_complaint_reference,
  "company-enums.ramos": company_enums_ramos,
  "company-enums.agencies": company_enums_agencies,
  "company-enums.complaint_public_status": company_enums_complaint_public_status,
  "company-enums.claim_public_status": company_enums_claim_public_status,
  "/claims": _claims,
  "/complaints": _complaints,
  "/companies": _companies,
  "/users": _users,
  "/integrations": _integrations,
  "/recordings": _recordings,
  "style-config": style_config,
  "fraud-investigation-case": fraud_investigation_case,
  role,
  webhook,
  resources,
  occurrence,
  currencies,
  company
};

// src/translate.ts
var translate = (key = "", data, fallback) => {
  const word = (0, import_lodash.default)(es_exports, key);
  if (!word) return fallback || "";
  const regex = /{{\s*([^{}]+)\s*}}/g;
  const final = word || key || "";
  return final?.replace(
    regex,
    (_, match) => String((0, import_lodash.default)(data, match))
  ) || "" || key;
};

// src/useTranslations.ts
var useTranslations = (namespace, _fallback) => (key, data, fallback) => translate(
  namespace ? `${namespace}.${key}` : key,
  data,
  fallback || _fallback
);
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  translate,
  useTranslations
});
